import React, { useState, useEffect, useCallback, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.js';
import debounce from 'lodash.debounce'; // or implement your own debounce function

import { 
  syncPlayback, 
  syncTimePosition, 
  initializeAudio as initAudioFromUtils, // Rename import to avoid conflict
  safeDestroyWaveSurfer, 
  safeCreateWaveSurfer,
  prepareFullscreenTransition,
  checkTransitionState,
  createVisualizationProcessor
} from '../../components/audio/audio-sync-fix';
import AudioDiagnosticsTool from '../../components/audio/AudioDiagnosticsTool';
import { createFallbackAudioBlob, getValidAudioUrl } from '../../components/audio/SilentAudioBlob';
import {
  FullScreenVisualizer,
  VisualizerLoader,
} from '../../components/audio/Visualizers';

import {
  IoPlayCircle,
  IoPauseCircle,
  IoPlaySkipBack,
  IoPlaySkipForward,
  IoList,
  IoClose,
  IoDownload,
  IoRefresh,
  IoImage,
  IoExpand,
  IoContract,
  IoVolumeHigh,
  IoVolumeOff
} from 'react-icons/io5';

const ListenStep = ({
  audioUrl: initialAudioUrl,
  onExportAudio,
  onReset,
  onError,
  toast,
  selectedMusic = "",
  musicLibrary = [],
  setSelectedMusic = () => {},
  onApplyChanges = () => {},
  baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://api.pause.site',
  isMixingAudio = false,
  onAudioLoaded = null,
  ttsKey = "",
  windowDimensions,
  mixedAudioInfo = null,
  getBestAudioUrl = null,
  centralizeAudioUrl = null,
  setIsMixingAudio = () => {}
}) => {
  // --- State Declarations ---
  const [audioUrl, setAudioUrl] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const [isLoadingAudio, setIsLoadingAudio] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showWaveSurferError, setShowWaveSurferError] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showPlaylist, setShowPlaylist] = useState(false);
  const [useVisualizer, setUseVisualizer] = useState(true);
  const [visualizerSize, setVisualizerSize] = useState(300);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth || 0);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight || 0);
  const [zoom, setZoom] = useState(50);
  const [volume, setVolume] = useState(1.0);
  const [isMuted, setIsMuted] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [initComplete, setInitComplete] = useState(false);
  const [failedLoadAttempts, setFailedLoadAttempts] = useState(0);
  const [loadingTimedOut, setLoadingTimedOut] = useState(false);
  const [hasMixedAudio, setHasMixedAudio] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Loading your meditation audio...');
  const [mixReady, setMixReady] = useState(false);
  const [isBackgroundTTSProcessing, setIsBackgroundTTSProcessing] = useState(false);
  const [backgroundTTSProgress, setBackgroundTTSProgress] = useState(0);
  const [globalStateInitialized, setGlobalStateInitialized] = useState(false);
  const [isUsingGlobalAudio, setIsUsingGlobalAudio] = useState(false);
  const [analyzerNode, setAnalyzerNode] = useState(null);
  const [componentError, setComponentError] = useState(null);
  const [componentReady, setComponentReady] = useState(false);
  const [wavesurferInitialized, setWavesurferInitialized] = useState(false);
  const [componentInitialized, setComponentInitialized] = useState(false);
  const [instanceId] = useState(`listen-${Date.now()}-${Math.floor(Math.random() * 1000)}`);

  

  // --- Refs ---
  const mounted = useRef(true);
  const isMountedRef = useRef(true);
  const waveformDataRef = useRef([]);
  const audioElementRef = useRef(null);
  const waveformContainerRef = useRef(null);
  const timelineContainerRef = useRef(null);
  const mobileWaveformContainerRef = useRef(null);
  const waveSurferRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyzerRef = useRef(null);
  const audioInitTimeoutRef = useRef(null);
  const safetyTimeoutsRef = useRef([]);
  const audioLoadTimeoutRef = useRef(null);
  const audioUrlRef = useRef(initialAudioUrl || '');
  const blobUrlsRef = useRef([]);
  const globalAudioRef = useRef(window.globalAudioState || null);
  const lastPlayingStateRef = useRef(false);
  const safeToastRef = useRef(null);
  const transitionHandledRef = useRef(false);

  const isMobile = windowWidth <= 768;
  const isFullscreenMobile = isFullscreen && isMobile;
  const globalAudioStateRef = useRef(null);


  const transitionCompleteRef = useRef(false);
  const abortControllersRef = useRef([]);
  const pendingTimeoutsRef = useRef([]);
  const analyzerInitializationInProgress = useRef(false);
  const initializationInProgress = useRef(false);
  const isDestroyingRef = useRef(false);

  // --- Utility functions, must be defined early ---
  
  // Validate URL
  const isValidUrl = useCallback((url) => {
    return !!(url && typeof url === 'string' &&
      (url.startsWith('http://') || url.startsWith('https://') || url.startsWith('blob:') || url.startsWith('data:')));
  }, []);

  // Format time display
  const formatTime = useCallback((timeInSeconds) => {
    if (isNaN(timeInSeconds) || timeInSeconds < 0) return '0:00';
    
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }, []);

  // Get track info for display - must be defined before using trackInfo
  const getTrackInfo = useCallback(() => {
    try {
      if (!selectedMusic || !Array.isArray(musicLibrary) || musicLibrary.length === 0) {
        return { title: 'Your Meditation', artist: 'Pause' };
      }
      
      const track = musicLibrary.find(t => t && typeof t === 'object' && t.name === selectedMusic);
      
      if (track) {
        return {
          title: track.displayName || track.name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
          artist: 'Pause Music'
        };
      } else {
        return {
          title: selectedMusic ? selectedMusic.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()) : 'Your Meditation',
          artist: 'Pause'
        };
      }
    } catch (err) {
      console.error("AUDIO PLAYER: Error in getTrackInfo:", err);
      return { title: 'Meditation', artist: 'Pause' };
    }
  }, [selectedMusic, musicLibrary]);

  // Define trackInfo early to avoid circular reference
  const trackInfo = getTrackInfo();

// Enhanced initializeAudioAnalysis function for ListenStep.js
// This improves analyzer creation and connection to audio sources

// The entire initializeAudioAnalysis function with the fixed dependency array

const initializeAudioAnalysis = useCallback(() => {
  // Don't initialize if already in progress or being destroyed
  if (analyzerInitializationInProgress.current || !mounted.current) {
    return false;
  }
  
  analyzerInitializationInProgress.current = true;
  console.log("AUDIO PLAYER: Initializing audio analysis for visualizer");
  
  // Make sure we have an audio element
  const audioElement = audioElementRef.current;
  if (!audioElement) {
    console.warn("AUDIO PLAYER: No audio element available for analysis");
    analyzerInitializationInProgress.current = false;
    return false;
  }
  
  try {
    // Try to use existing global analyzer if available
    if (window.globalAudioState && window.globalAudioState._analyzer) {
      console.log("AUDIO PLAYER: Using existing global analyzer");
      
      // Check if the analyzer is actually working by testing its methods
      let analyzerIsValid = false;
      try {
        const testArray = new Uint8Array(128);
        window.globalAudioState._analyzer.getByteFrequencyData(testArray);
        // Check if we got any non-zero values (might still be all zeros if audio is silent)
        analyzerIsValid = true;
      } catch (err) {
        console.warn("AUDIO PLAYER: Existing analyzer failed validation test:", err);
        analyzerIsValid = false;
      }
      
      if (analyzerIsValid) {
        analyzerRef.current = window.globalAudioState._analyzer;
        setAnalyzerNode(window.globalAudioState._analyzer);
        analyzerInitializationInProgress.current = false;
        return true;
      } else {
        console.log("AUDIO PLAYER: Existing analyzer failed validation, creating new one");
      }
    }
    
    // Use existing AudioContext or create a new one
    if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
      console.log("AUDIO PLAYER: Using existing AudioContext");
    } else {
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      if (!AudioContext) {
        console.warn("AUDIO PLAYER: AudioContext not supported in this browser");
        analyzerInitializationInProgress.current = false;
        
        // Use fallback instead
        const fallbackAnalyzer = createVisualizationProcessor(null);
        analyzerRef.current = fallbackAnalyzer;
        setAnalyzerNode(fallbackAnalyzer);
        
        if (window.globalAudioState) {
          window.globalAudioState._analyzer = fallbackAnalyzer;
        }
        
        return true;
      }
      
      audioContextRef.current = new AudioContext();
      console.log("AUDIO PLAYER: Created new AudioContext");
    }
    
    // Create analyzer node with enhanced settings
    const analyzer = audioContextRef.current.createAnalyser();
    analyzer.fftSize = 2048; // Larger FFT size for more detailed data
    analyzer.smoothingTimeConstant = 0.7; // Lower value = more responsive (was 0.85)
    analyzer.minDecibels = -90; // Lower threshold to capture quieter sounds
    analyzer.maxDecibels = -10; // Upper threshold for louder sounds
    
    console.log("AUDIO PLAYER: Created analyzer with enhanced sensitivity settings");
    
    // Track connected audio elements to prevent double connection
    if (!window._connectedAudioElements) {
      window._connectedAudioElements = new WeakMap();
    }
    
    // First check if the audio element is already connected
    let sourceNode;
    let isNewConnection = false;
    
    if (window._connectedAudioElements.has(audioElement)) {
      // Get existing source node
      sourceNode = window._connectedAudioElements.get(audioElement);
      console.log("AUDIO PLAYER: Using existing source connection");
    } else {
      try {
        // Create new connection
        sourceNode = audioContextRef.current.createMediaElementSource(audioElement);
        window._connectedAudioElements.set(audioElement, sourceNode);
        isNewConnection = true;
        console.log("AUDIO PLAYER: Created new MediaElementSource connection");
      } catch (err) {
        // This error often happens if the element is already connected
        console.warn("AUDIO PLAYER: Error creating MediaElementSource (might be already connected):", err);
        
        // Create a silent oscillator as reliable audio source instead
        const oscillator = audioContextRef.current.createOscillator();
        oscillator.frequency.value = 0; // Silent
        const gainNode = audioContextRef.current.createGain();
        gainNode.gain.value = 0; // Silent
        
        // Connect oscillator -> gain -> analyzer -> destination
        oscillator.connect(gainNode);
        gainNode.connect(analyzer);
        analyzer.connect(audioContextRef.current.destination);
        oscillator.start();
        
        // Store cleanup function
        analyzer._cleanup = () => {
          try {
            oscillator.stop();
            oscillator.disconnect();
            gainNode.disconnect();
            analyzer.disconnect();
          } catch (e) {
            // Ignore cleanup errors
          }
        };
        
        // Store and share the analyzer
        analyzerRef.current = analyzer;
        setAnalyzerNode(analyzer);
        
        if (window.globalAudioState) {
          window.globalAudioState._analyzer = analyzer;
        }
        
        // Resume context if needed
        if (audioContextRef.current.state === 'suspended') {
          audioContextRef.current.resume().catch(err => {
            console.warn("AUDIO PLAYER: Error resuming AudioContext:", err);
          });
        }
        
        console.log("AUDIO PLAYER: Using oscillator fallback for analyzer");
        analyzerInitializationInProgress.current = false;
        return true;
      }
    }
    
    // If we have a source node, connect it properly
    if (sourceNode) {
      if (isNewConnection) {
        // Connect the source to both the analyzer and the destination
        sourceNode.connect(analyzer);
        sourceNode.connect(audioContextRef.current.destination);
        console.log("AUDIO PLAYER: Connected source to analyzer and destination");
      } else {
        // For existing connections, we just connect the analyzer
        try {
          sourceNode.connect(analyzer);
          console.log("AUDIO PLAYER: Connected analyzer to existing source");
        } catch (err) {
          console.warn("AUDIO PLAYER: Error connecting to existing source:", err);
        }
      }
      
      // Connect analyzer to destination if it's not already
      try {
        analyzer.connect(audioContextRef.current.destination);
      } catch (err) {
        console.warn("AUDIO PLAYER: Error connecting analyzer to destination:", err);
      }
    }
    
    // Add helper methods to the analyzer to make visualization easier
    analyzer.getAudioLevels = () => {
      try {
        if (!analyzer.frequencyDataArray) {
          analyzer.frequencyDataArray = new Uint8Array(analyzer.frequencyBinCount);
        }
        
        analyzer.getByteFrequencyData(analyzer.frequencyDataArray);
        
        // Calculate frequency bands with enhanced sensitivity
        let bass = 0, mid = 0, treble = 0;
        let bassCount = 0, midCount = 0, trebleCount = 0;
        
        // Low frequencies (bass) - frequencies roughly up to 250Hz
        const bassRange = Math.floor(analyzer.frequencyBinCount * 0.15);
        for (let i = 1; i < bassRange; i++) {
          bass += analyzer.frequencyDataArray[i] || 0;
          bassCount++;
        }
        
        // Mid frequencies - up to about 2kHz
        const midRange = Math.floor(analyzer.frequencyBinCount * 0.5);
        for (let i = bassRange; i < midRange; i++) {
          mid += analyzer.frequencyDataArray[i] || 0;
          midCount++;
        }
        
        // High frequencies (treble) - 2kHz and up
        const trebleRange = Math.floor(analyzer.frequencyBinCount * 0.8);
        for (let i = midRange; i < trebleRange; i++) {
          treble += analyzer.frequencyDataArray[i] || 0;
          trebleCount++;
        }
        
        // Normalize and apply enhanced sensitivity
        const sensitivity = 1.8; // Increased from typical 1.5
        bass = bassCount > 0 ? Math.pow((bass / bassCount) / 255, 0.8) * sensitivity : 0;
        mid = midCount > 0 ? Math.pow((mid / midCount) / 255, 0.7) * sensitivity : 0;
        treble = trebleCount > 0 ? Math.pow((treble / trebleCount) / 255, 0.6) * sensitivity : 0;
        
        // Apply voice-focused EQ curve (emphasize mid frequencies)
        mid *= 1.5;  // Boost mids even more for voice
        bass *= 1.2;  // Slightly boost bass too
        
        return { bass, mid, treble, isSimulated: false };
      } catch (err) {
        console.warn("AUDIO PLAYER: Error getting audio levels:", err);
        return { bass: 0.3, mid: 0.3, treble: 0.3, isSimulated: true };
      }
    };
    
    // Add cleanup method
    analyzer._cleanup = () => {
      try {
        analyzer.disconnect();
      } catch (e) {
        // Ignore cleanup errors
      }
    };
    
    // Store and share the analyzer
    analyzerRef.current = analyzer;
    setAnalyzerNode(analyzer);
    
    if (window.globalAudioState) {
      window.globalAudioState._analyzer = analyzer;
    }
    
    // Resume context if needed
    if (audioContextRef.current.state === 'suspended') {
      audioContextRef.current.resume().catch(err => {
        console.warn("AUDIO PLAYER: Error resuming AudioContext:", err);
      });
    }
    
    analyzerInitializationInProgress.current = false;
    return true;
  } catch (error) {
    console.error("AUDIO PLAYER: Error in initializeAudioAnalysis:", error);
    
    // Create a fallback for catastrophic failures
    const fallbackAnalyzer = createVisualizationProcessor ? 
      createVisualizationProcessor(null) : 
      {
        frequencyBinCount: 1024,
        getByteFrequencyData: function(dataArray) {
          const t = Date.now() / 1000;
          for (let i = 0; i < dataArray.length; i++) {
            const base = 80 * Math.pow(1 - (i / dataArray.length), 1.3);
            const mod = isPlaying ? 
              30 * Math.sin(t * 2 + i * 0.05) + 15 * Math.sin(t * 0.7 + i * 0.15) :
              10 * Math.sin(t * 0.5 + i * 0.05) + 5 * Math.sin(t * 0.3 + i * 0.15);
            dataArray[i] = Math.max(0, Math.min(255, base + mod));
          }
        },
        _cleanup: function() {}
      };
    
    analyzerRef.current = fallbackAnalyzer;
    setAnalyzerNode(fallbackAnalyzer);
    
    if (window.globalAudioState) {
      window.globalAudioState._analyzer = fallbackAnalyzer;
    }
    
    analyzerInitializationInProgress.current = false;
    return true;
  }
}, [createVisualizationProcessor, isPlaying, mounted]); // Fixed: Changed mountedRef to mounted

  // Store safeToast in ref for access in callbacks
  const safeToast = useCallback((type, message, options = {}) => {
    if (!mounted.current) return null;
    try {
      if (globalAudioRef.current && typeof globalAudioRef.current.publishEvent === 'function') {
        globalAudioRef.current.publishEvent('toast', { 
          type, 
          message, 
          options, 
          source: instanceId, 
          timestamp: Date.now() 
        });
      }
      if (toast) {
        if (typeof toast[type] === 'function') {
          return toast[type](message, options);
        } else if (typeof toast === 'function') {
          return toast(message, { ...options, type });
        }
      }
    } catch (err) {
      console.log(`Error showing toast: ${err.message}`);
    }
    console.log(`Toast (${type}): ${message}`);
    return null;
  }, [toast, instanceId, globalAudioRef]);

  useEffect(() => {
    safeToastRef.current = safeToast;
  }, [safeToast]);

  const handleAudioReady = useCallback((event) => {
    if (!mounted.current) return;
    
    console.log("AUDIO PLAYER: Audio ready event received");
    setAudioLoaded(true);
    setIsLoadingAudio(false);
    
    // Update duration from event or audio element
    if (event && event.duration) {
      setDuration(event.duration);
    } else if (audioElementRef.current) {
      setDuration(audioElementRef.current.duration || 0);
    }
    
    // Initialize analyzer for visualizer if needed
    if (!analyzerRef.current) {
      initializeAudioAnalysis();
    }
    
    // Notify parent component if callback provided
    if (onAudioLoaded) {
      onAudioLoaded({ 
        url: audioUrl, 
        duration: event?.duration || audioElementRef.current?.duration || 0 
      });
    }
  }, [audioUrl, onAudioLoaded, initializeAudioAnalysis]);

  // Generate global CSS styles
  const getGlobalStyles = useCallback((isMobile) => `
  @keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }
  @keyframes pulse { 0% { transform: scale(0.9); opacity: 0.7; } 100% { transform: scale(1.1); opacity: 0.9; } }
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: ${isMobile ? '20px' : '16px'};
    height: ${isMobile ? '20px' : '16px'};
    border-radius: 50%;
    background: #7d12ff;
    cursor: pointer;
    box-shadow: 0 0 6px rgba(125,18,255,0.4);
  }
  input[type="range"]::-moz-range-thumb {
    width: ${isMobile ? '20px' : '16px'};
    height: ${isMobile ? '20px' : '16px'};
    border-radius: 50%;
    background: #7d12ff;
    cursor: pointer;
    border: none;
    box-shadow: 0 0 6px rgba(125,18,255,0.4);
  }
  button:hover:not(:disabled) { transform: translateY(-2px); box-shadow: 0 6px 16px rgba(125,18,255,0.3); }
  button:active:not(:disabled) { transform: translateY(0); }
  
  /* Hide toast notifications in ListenStep */
  body.in-listen-step .Toastify__toast-container {
    display: none !important;
  }
  
  @media (max-width: 768px) {
    input[type="range"] { height: 12px; padding: 8px 0; }
    input[type="checkbox"] { width: 20px; height: 20px; }
    select { height: 44px; }
  }
  `, []);

  // 1. Toggle playlist visibility
  const togglePlaylist = useCallback(() => {
    setShowPlaylist(prev => !prev);
  }, []);

  // 2. Toggle visualizer effects
  const toggleVisualizer = useCallback(() => {
    setUseVisualizer(prev => !prev);
  }, []);

  // 3. Handle music selection (make sure this matches your component's props)
  const handleMusicSelect = useCallback((trackName) => {
    setSelectedMusic(trackName);
    setShowPlaylist(false);
    
    // Publish event to global audio state if available
    if (globalAudioRef.current && typeof globalAudioRef.current.publishEvent === 'function') {
      globalAudioRef.current.publishEvent('music-selected', {
        trackName, 
        timestamp: Date.now()
      });
    }
    
    // Apply changes via callback if provided
    if (typeof onApplyChanges === 'function') {
      setIsLoadingAudio(true);
      
      onApplyChanges().catch(err => {
        console.error("AUDIO PLAYER: Error in onApplyChanges:", err);
        
        if (safeToastRef.current) {
          safeToastRef.current('error', 'Failed to change music track');
        }
        
        setIsLoadingAudio(false);
      });
    }
  }, [setSelectedMusic, onApplyChanges, safeToastRef, globalAudioRef]);

  // Generate album art for display
  const getAlbumArt = useCallback((trackName = selectedMusic) => {
    const coverArtOptions = [
      'https://images.unsplash.com/photo-1470071459604-3b5ec3a7fe05?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1447752875215-b2761acb3c5d?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1433086966358-54859d0ed716?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1501854140801-50d01698950b?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1528722828814-77b9b83aafb2?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1475274047050-1d0c0975c63e?w=600&h=600&fit=crop&auto=format'
    ];
    
    if (!trackName) return coverArtOptions[0];
    
    try {
      const cleanName = trackName.replace(/^Background_Music\/?/i, '').replace(/\.mp3$/i, '').trim();
      let hash = 0;
      
      for (let j = 0; j < cleanName.length; j++) {
        hash = (hash << 5) - hash + cleanName.charCodeAt(j);
        hash |= 0;
      }
      
      const index = Math.abs(hash) % coverArtOptions.length;
      return coverArtOptions[index];
    } catch (error) {
      console.error('Error getting cover art:', error);
      return coverArtOptions[0];
    }
  }, [selectedMusic]);

  // Generate mood label for tracks
  const getMoodLabel = useCallback((trackName = selectedMusic) => {
    if (!trackName) return 'Meditation Music';
    
    try {
      const cleanTrackName = trackName.replace(/^Background_Music\/?/i, '').replace(/\.mp3$/i, '').trim();
      const lowerName = cleanTrackName.toLowerCase();
      
      const moodMap = [
        { keywords: ['calm', 'peaceful', 'gentle'], label: 'Calm & Peaceful' },
        { keywords: ['ocean', 'sea', 'water', 'wave'], label: 'Ocean Soundscape' },
        { keywords: ['rain', 'storm', 'thunder'], label: 'Rainfall Ambience' },
        { keywords: ['forest', 'nature', 'bird'], label: 'Forest Sounds' },
        { keywords: ['night', 'evening', 'twilight'], label: 'Night Ambience' },
        { keywords: ['space', 'cosmic', 'universe'], label: 'Cosmic Journey' },
        { keywords: ['meditat', 'zen', 'mindful'], label: 'Deep Meditation' },
        { keywords: ['dream', 'sleep', 'relax'], label: 'Sleep & Relaxation' },
        { keywords: ['piano', 'string', 'classic'], label: 'Gentle Classical' },
        { keywords: ['ambient', 'atmosphere'], label: 'Ambient Atmosphere' },
        { keywords: ['journey', 'adventure'], label: 'Inner Journey' }
      ];
      
      for (const mood of moodMap) {
        if (mood.keywords.some(keyword => lowerName.includes(keyword))) {
          return mood.label;
        }
      }
      
      return cleanTrackName.length > 15 ? 'Meditation Music' : 'Relaxing Ambience';
    } catch (error) {
      console.error('Error getting mood label:', error);
      return 'Meditation Music';
    }
  }, [selectedMusic]);

  useEffect(() => {
    const handleDimensionUpdate = () => {
      setWindowWidth(window.innerWidth || 0);
      setWindowHeight(window.innerHeight || 0);
    };
    
    // Use a throttled update to prevent rapid firing
    let resizeTimeout;
    const throttledUpdate = () => {
      if (!resizeTimeout) {
        resizeTimeout = setTimeout(() => {
          handleDimensionUpdate();
          resizeTimeout = null;
        }, 100);
      }
    };
    
    window.addEventListener('resize', throttledUpdate);
    
    return () => {
      window.removeEventListener('resize', throttledUpdate);
      if (resizeTimeout) clearTimeout(resizeTimeout);
    };
  }, []); // Empty dependency array - only track window dimensions

  // Ensure proper container dimensions for wavesurfer - MOVED UP before initializeWaveSurfer
  const ensureContainerDimensions = useCallback((container, isMobile) => {
    if (!container) return;
    
    try {
      // Force important styles to prevent CSS conflicts
      container.style.setProperty('display', 'block', 'important');
      container.style.setProperty('visibility', 'visible', 'important');
      container.style.setProperty('width', '100%', 'important');
      container.style.setProperty('min-width', '300px', 'important');
      container.style.setProperty('height', isMobile ? '60px' : '80px', 'important');
      container.style.setProperty('min-height', isMobile ? '60px' : '80px', 'important');
      
      // Force reflow to apply styles
      void container.offsetHeight;
      void container.offsetWidth;
      
      // Ensure container has an ID
      if (!container.id) {
        container.id = `wavesurfer-container-${Date.now()}`;
      }
    } catch (e) {
      console.warn("Error fixing container dimensions:", e);
    }
  }, []);

  // Create fallback audio element - defined before being used in initializeWaveSurfer
  const createFallbackAudioElement = useCallback((url) => {
    if (!mounted.current) return null;
    
    console.log("AUDIO PLAYER: Creating fallback audio element with URL:", url);
    
    try {
      // Create a new audio element
      const audioEl = new Audio();
      
      // Set properties
      audioEl.crossOrigin = "anonymous";
      audioEl.preload = "auto";
      audioEl.autoplay = false;
      
      // Set error handling
      audioEl.onerror = (error) => {
        console.error("AUDIO PLAYER: Fallback audio element error:", error);
        if (safeToastRef.current) {
          safeToastRef.current('error', 'Audio playback error. Please try again.');
        }
        if (onError) {
          onError(error);
        }
      };
      
      // Setup event listeners
      audioEl.addEventListener('loadedmetadata', () => {
        if (!mounted.current) return;
        console.log("AUDIO PLAYER: Fallback audio metadata loaded, duration:", audioEl.duration);
        setDuration(audioEl.duration || 0);
        setAudioLoaded(true);
        setIsLoadingAudio(false);
        if (onAudioLoaded) {
          onAudioLoaded({ url, duration: audioEl.duration || 0 });
        }
      });
      
      audioEl.addEventListener('timeupdate', () => {
        if (!mounted.current) return;
        setCurrentTime(audioEl.currentTime || 0);
      });
      
      audioEl.addEventListener('play', () => {
        if (!mounted.current) return;
        setIsPlaying(true);
        lastPlayingStateRef.current = true;
        if (globalAudioRef.current) {
          globalAudioRef.current.state.audioPlaying = true;
        }
      });
      
      audioEl.addEventListener('pause', () => {
        if (!mounted.current) return;
        setIsPlaying(false);
        lastPlayingStateRef.current = false;
        if (globalAudioRef.current) {
          globalAudioRef.current.state.audioPlaying = false;
        }
      });
      
      audioEl.addEventListener('ended', () => {
        if (!mounted.current) return;
        setIsPlaying(false);
        setCurrentTime(0);
        lastPlayingStateRef.current = false;
        if (globalAudioRef.current) {
          globalAudioRef.current.state.audioPlaying = false;
        }
      });
      
      // Mark listeners as initialized to prevent duplication
      audioEl._listenersInitialized = true;
      
      // Set source if URL is valid
      if (url && isValidUrl(url)) {
        audioEl.src = url;
        audioEl.load();
      } else {
        console.warn("AUDIO PLAYER: Invalid URL provided to fallback audio element");
        // Try to create a silent audio blob as a last resort
        const silentUrl = createFallbackAudioBlob();
        audioEl.src = silentUrl;
        audioEl.load();
      }
      
      // Store the element in ref and global state
      audioElementRef.current = audioEl;
      if (globalAudioRef.current && !globalAudioRef.current._audioElement) {
        globalAudioRef.current._audioElement = audioEl;
      }
      
      // Return the created element
      return audioEl;
    } catch (error) {
      console.error("AUDIO PLAYER: Error creating fallback audio element:", error);
      setIsLoadingAudio(false);
      if (safeToastRef.current) {
        safeToastRef.current('error', 'Unable to initialize audio player. Please refresh and try again.');
      }
      return null;
    }
  }, [isValidUrl, onAudioLoaded, onError, createFallbackAudioBlob]);



  // Load audio into WaveSurfer - must be defined before initializeWaveSurfer
  const loadAudioIntoWaveSurfer = useCallback((url, wsInstance) => {
    if (!url || !wsInstance || wsInstance._destroyed || wsInstance._isBeingDestroyed) {
      console.warn("AUDIO PLAYER: Cannot load audio - invalid URL or WaveSurfer instance");
      return false;
    }
    
    // Add a flag to track the current loading operation
    wsInstance._currentLoadingUrl = url;
    console.log("AUDIO PLAYER: Loading audio into WaveSurfer:", url);
    
    try {
      // Cancel any previous load operation
      if (wsInstance._abortController && typeof wsInstance._abortController.abort === 'function') {
        wsInstance._abortController.abort();
      }
      
      // Create new abort controller
      const controller = new AbortController();
      wsInstance._abortController = controller;
      abortControllersRef.current.push(controller);
      
      // Track retry attempts
      let retryCount = 0;
      const maxRetries = 3;
      
      const attemptLoad = () => {
        // Don't proceed if component is unmounted or the instance is being destroyed
        if (!mounted.current || wsInstance._destroyed || wsInstance._isBeingDestroyed) {
          console.warn("AUDIO PLAYER: WaveSurfer instance is destroyed or component unmounted, cannot load audio");
          return false;
        }
        
        // Check if backend is ready, if not retry
        if (wsInstance.backend && wsInstance.backend.isPaused === null) {
          if (retryCount < maxRetries) {
            retryCount++;
            console.log(`AUDIO PLAYER: Retrying load (${retryCount}/${maxRetries}) due to backend not ready...`);
            const timeoutId = setTimeout(attemptLoad, 800);
            pendingTimeoutsRef.current.push(timeoutId);
            return false;
          }
        }
        
        // Wrap the load call with a timeout promise
        new Promise((resolve, reject) => {
          const timeout = setTimeout(() => reject(new Error("Loading timed out")), 15000); // 15 second timeout
          pendingTimeoutsRef.current.push(timeout);
          
          try {
            // Set up event listeners for success and error
            const onReadyHandler = () => {
              clearTimeout(timeout);
              wsInstance.un('ready', onReadyHandler);
              wsInstance.un('error', onErrorHandler);
              resolve(true);
            };
            
            const onErrorHandler = (error) => {
              clearTimeout(timeout);
              wsInstance.un('ready', onReadyHandler);
              wsInstance.un('error', onErrorHandler);
              reject(error);
            };
            
            wsInstance.once('ready', onReadyHandler);
            wsInstance.once('error', onErrorHandler);
            
            // Try to load with media if backend is MediaElement
            if (wsInstance.backend && wsInstance.backend.name === 'MediaElement' && wsInstance.backend.media) {
              try {
                wsInstance.backend.media.src = url;
                wsInstance.backend.media.load();
              } catch (mediaErr) {
                console.warn("AUDIO PLAYER: Error loading via media element:", mediaErr);
              }
            }
            
            // Load the audio
            wsInstance.load(url);
          } catch (innerErr) {
            clearTimeout(timeout);
            reject(innerErr);
          }
        })
        .then(() => {
          console.log("AUDIO PLAYER: Audio loaded successfully in WaveSurfer");
          return true;
        })
        .catch(err => {
          // Don't log abort errors as they're expected during cleanup
          if (err?.name === 'AbortError' || err?.message?.includes('abort')) {
            console.warn("AUDIO PLAYER: Error loading audio in WaveSurfer:", err);
            
            // Retry if the error is related to isPaused
            if (err?.toString().includes("isPaused") && retryCount < maxRetries) {
              retryCount++;
              const timeoutId = setTimeout(attemptLoad, 800);
              pendingTimeoutsRef.current.push(timeoutId);
              return false;
            }
            
            // Try one last fallback approach using the audio element directly
            if (audioElementRef.current && retryCount >= maxRetries) {
              try {
                console.log("AUDIO PLAYER: Creating fallback audio element with URL:", url);
                createFallbackAudioElement(url);
                setAudioLoaded(true);
                setIsLoadingAudio(false);
                return true;
              } catch (fallbackErr) {
                console.warn("AUDIO PLAYER: Fallback audio element also failed:", fallbackErr);
              }
            }
          }
          
          return false;
        });
        
        return true;
      };
      
      return attemptLoad();
    } catch (error) {
      console.error("AUDIO PLAYER: Error in loadAudioIntoWaveSurfer:", error);
      return false;
    }
  }, [mounted, createFallbackAudioElement, audioElementRef]);


  const initializeWaveSurfer = useCallback(() => {
    // Don't initialize if already in progress or being destroyed
    if (initializationInProgress.current || isDestroyingRef.current) {
      return null;
    }
    
    initializationInProgress.current = true;
    console.log("AUDIO PLAYER: Initializing WaveSurfer with scrolling view");
    
    // First validate that we have a stable audio element
    if (!audioElementRef.current) {
      console.warn("AUDIO PLAYER: No audio element available for WaveSurfer");
      initializationInProgress.current = false;
      return null;
    }
    
    // Get the appropriate container based on display mode
    const container = isFullscreenMobile ? mobileWaveformContainerRef.current : waveformContainerRef.current;
    
    if (!container) {
      console.warn("AUDIO PLAYER: No container found for WaveSurfer initialization");
      initializationInProgress.current = false;
      return null;
    }
    
    // CRITICAL: Wait for any existing instances to be fully destroyed
    if (container._wavesurfer && container._wavesurfer._isBeingDestroyed) {
      console.log("AUDIO PLAYER: Container has wavesurfer being destroyed, delaying initialization");
      const timeoutId = setTimeout(() => {
        initializationInProgress.current = false;
        if (mounted.current) initializeWaveSurfer();
      }, 500);
      pendingTimeoutsRef.current.push(timeoutId);
      return null;
    }
    
    // Ensure container has proper dimensions
    ensureContainerDimensions(container, isMobile);
    
    // First check if we have a cached WaveSurfer instance in the container
    if (container._wavesurfer && !container._wavesurfer._destroyed && 
        !container._wavesurfer._isBeingDestroyed && 
        typeof container._wavesurfer.destroy === 'function') {
      console.log("AUDIO PLAYER: Reusing existing WaveSurfer instance from container");
      waveSurferRef.current = container._wavesurfer;
      initializationInProgress.current = false;
      return container._wavesurfer;
    }
    
    // Create WaveSurfer options with plugins
    const plugins = [];
    
    // Add timeline plugin if not in mobile fullscreen mode
    if (!isFullscreenMobile && timelineContainerRef.current && TimelinePlugin) {
      try {
        const timelinePlugin = TimelinePlugin.create({
          container: timelineContainerRef.current,
          primaryLabelInterval: 10,
          secondaryLabelInterval: 5,
          primaryColor: 'rgba(255,255,255,0.8)',
          secondaryColor: 'rgba(255,255,255,0.4)',
          primaryFontColor: 'rgba(255,255,255,0.8)',
          secondaryFontColor: 'rgba(255,255,255,0.4)'
        });
        plugins.push(timelinePlugin);
      } catch (err) {
        console.warn("AUDIO PLAYER: Error creating timeline plugin:", err);
      }
    }
    
    // Add regions plugin for potential future use
    try {
      if (RegionsPlugin) {
        const regionsPlugin = RegionsPlugin.create();
        plugins.push(regionsPlugin);
      }
    } catch (err) {
      console.warn("AUDIO PLAYER: Error creating regions plugin:", err);
    }
    
    // Key configurations for scrolling waveform
    const autoScroll = true;
    const autoCenter = true;
    const scrollRatio = 0.3; // Controls how much of the waveform is visible
    
    // Create WaveSurfer instance using the utility function with scrolling options
    const wsInstance = safeCreateWaveSurfer({
      container,
      WaveSurfer,
      audioElement: audioElementRef.current,
      isMobile,
      waveColor: 'rgba(255, 255, 255, 0.6)',
      progressColor: '#7d12ff',
      plugins,
      // These parameters enable the scrolling effect
      minPxPerSec: isMobile ? 50 : 100, // Zoom level - higher value = more zoomed in
      scrollParent: true,               // Enable scrolling parent container
      fillParent: false,                // Don't fill parent - important for scrolling
      autoScroll: autoScroll,           // Auto-scroll during playback
      autoCenter: autoCenter,           // Auto-center on the current position
      hideScrollbar: false,             // Show scrollbar for user control
      normalize: true,                  // Normalize waveform heights
      barWidth: isMobile ? 2 : 3,
      barGap: isMobile ? 1 : 2,
      barRadius: 2,
      cursorWidth: 2,                   // Show cursor for current position
      cursorColor: '#7d12ff',           // Cursor color
      mediaControls: false,             // Prevent WaveSurfer from controlling media element playback

      onReady: (wsInstance) => {
        if (!mounted.current || isDestroyingRef.current) return;
        
        console.log("AUDIO PLAYER: WaveSurfer ready event fired");
        wsInstance._isInitialized = true;
        setAudioLoaded(true);
        setIsLoadingAudio(false);
        initializationInProgress.current = false;
        setWavesurferInitialized(true);
        
        try {
          const dur = wsInstance.getDuration();
          if (dur && !isNaN(dur) && isFinite(dur)) {
            setDuration(dur);
            if (onAudioLoaded) {
              onAudioLoaded({ url: audioUrl, duration: dur, wavesurferReady: true });
            }
          }
          
          // Set zoom level after initialization
          if (wsInstance.zoom) {
            // Apply zoom - this controls how "zoomed in" the waveform appears
            wsInstance.zoom(scrollRatio * wsInstance.drawer.getWidth());
          }
        } catch (err) {
          console.warn("AUDIO PLAYER: Error initializing wavesurfer settings:", err);
        }
        
        // Initialize audio analysis for visualizer if needed
        if (!analyzerRef.current && initializeAudioAnalysis) {
          const timeoutId = setTimeout(() => {
            if (mounted.current) {
              initializeAudioAnalysis();
            }
          }, 100);
          pendingTimeoutsRef.current.push(timeoutId);
        }
      },
      onError: (err) => {
        if (!mounted.current || isDestroyingRef.current) return;
        
        // Don't treat abort errors as real errors
        if (err?.name === 'AbortError' || err?.message?.includes('abort')) {
          console.log("AUDIO PLAYER: Load aborted - this is expected during cleanup");
          initializationInProgress.current = false;
          return;
        }
        
        console.error("AUDIO PLAYER: WaveSurfer error:", err);
        initializationInProgress.current = false;
        setIsLoadingAudio(false);
        setShowWaveSurferError(true);
        
        // Fall back to direct audio element
        if (audioUrl && isValidUrl(audioUrl)) {
          createFallbackAudioElement(audioUrl);
          setAudioLoaded(true);
        }
        
        if (onError) onError(err);
      }
    });
    
    // Store the new instance
    waveSurferRef.current = wsInstance;
    
    // Set up safety timeout for initialization
    const timeoutId = setTimeout(() => {
      initializationInProgress.current = false;
      
      if (mounted.current && wsInstance && !wsInstance._isInitialized) {
        console.warn("AUDIO PLAYER: WaveSurfer initialization timed out");
        
        // Try to salvage the situation
        if (!audioLoaded && audioElementRef.current && audioUrl) {
          console.log("AUDIO PLAYER: Falling back to direct audio element");
          setAudioLoaded(true);
          setIsLoadingAudio(false);
        }
      }
    }, 10000); // 10 second timeout
    
    pendingTimeoutsRef.current.push(timeoutId);
    
    return wsInstance;
  }, [
    isFullscreenMobile, 
    isMobile, 
    audioUrl, 
    isValidUrl, 
    onAudioLoaded, 
    onError,
    createFallbackAudioElement, 
    ensureContainerDimensions,
    safeCreateWaveSurfer,
    initializeAudioAnalysis,
    audioLoaded
  ]);

  // Add a function to update WaveSurfer view when toggling fullscreen
  const updateWaveSurferView = useCallback(() => {
    if (!waveSurferRef.current || waveSurferRef.current._destroyed) return;
    
    try {
      // Avoid unnecessary operations that might cause reinitializations
      waveSurferRef.current.drawer.containerWidth = waveSurferRef.current.drawer.container.clientWidth;
      waveSurferRef.current.drawBuffer();
      
      // Only adjust essential properties, avoid complete recreations
      if (waveSurferRef.current.getCurrentTime && waveSurferRef.current.getDuration) {
        const currentTime = waveSurferRef.current.getCurrentTime();
        const duration = waveSurferRef.current.getDuration();
        if (duration > 0) {
          waveSurferRef.current.seekTo(currentTime / duration);
        }
      }
    } catch (err) {
      console.warn("Error updating WaveSurfer view:", err);
    }
  }, []);

  useEffect(() => {
    let observer;
    
    try {
      observer = new ResizeObserver((entries) => {
        // Only redraw WaveSurfer, don't reinitialize
        if (waveSurferRef.current && !waveSurferRef.current._destroyed) {
          updateWaveSurferView();
        }
      });
      
      // Observe both regular and mobile containers
      if (waveformContainerRef.current) {
        observer.observe(waveformContainerRef.current);
      }
      if (mobileWaveformContainerRef.current) {
        observer.observe(mobileWaveformContainerRef.current);
      }
    } catch (err) {
      // Fallback to window resize if ResizeObserver isn't available
      console.warn("ResizeObserver not supported, using window resize fallback");
      const handleResize = () => {
        if (waveSurferRef.current && !waveSurferRef.current._destroyed) {
          updateWaveSurferView();
        }
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [updateWaveSurferView]);

  // Toggle playback (play/pause)
  const togglePlayback = useCallback(() => {
    console.log("AUDIO PLAYER: Toggle playback called, current state:", isPlaying);
    
    // Use the sync function from audio-sync-fix.js
    const success = syncPlayback({
      isPlaying: !isPlaying,
      audioElement: audioElementRef.current,
      waveSurfer: waveSurferRef.current,
      globalAudioState: globalAudioRef.current
    });
    
    if (success) {
      setIsPlaying(!isPlaying);
      return;
    }
    
    // Fallback if the sync function failed
    console.log("AUDIO PLAYER: syncPlayback failed, using fallback approach");
    
    try {
      if (waveSurferRef.current && !waveSurferRef.current._destroyed) {
        try {
          const wsPlaying = waveSurferRef.current.isPlaying();
          if (wsPlaying) {
            waveSurferRef.current.pause();
          } else {
            waveSurferRef.current.play();
          }
          setIsPlaying(!wsPlaying);
          return;
        } catch (err) {
          console.warn("AUDIO PLAYER: Error toggling WaveSurfer playback:", err);
        }
      }
      
      if (audioElementRef.current) {
        if (audioElementRef.current.paused) {
          const playPromise = audioElementRef.current.play();
          if (playPromise !== undefined) {
            playPromise.catch(err => {
              console.warn("AUDIO PLAYER: Error playing audio:", err);
              
              // Handle autoplay restrictions
              if (err.name === 'NotAllowedError') {
                if (safeToastRef.current) {
                  safeToastRef.current('info', 'Audio playback requires user interaction first.');
                }
                
                // Add interaction handlers
                const handleUserInteraction = () => {
                  if (audioElementRef.current) {
                    audioElementRef.current.play().catch(e => {
                      console.warn("AUDIO PLAYER: Second play attempt failed:", e);
                    });
                  }
                  // Remove listeners after first interaction
                  document.removeEventListener('click', handleUserInteraction);
                  document.removeEventListener('touchstart', handleUserInteraction);
                  document.removeEventListener('keydown', handleUserInteraction);
                };
                
                document.addEventListener('click', handleUserInteraction, { once: true });
                document.addEventListener('touchstart', handleUserInteraction, { once: true });
                document.addEventListener('keydown', handleUserInteraction, { once: true });
              }
            });
          }
          setIsPlaying(true);
        } else {
          audioElementRef.current.pause();
          setIsPlaying(false);
        }
        return;
      }
    } catch (error) {
      console.error("AUDIO PLAYER: Error in togglePlayback:", error);
      
      if (safeToastRef.current) {
        safeToastRef.current('error', 'Audio player not responding. Please try again.');
      }
    }
    
    // Last resort: just toggle the state even if nothing else worked
    setIsPlaying(!isPlaying);
  }, [isPlaying, syncPlayback]);

  // Handle time skipping
  const handleSkip = useCallback((seconds) => {
    try {
      if (waveSurferRef.current && !waveSurferRef.current._destroyed) {
        const currentTime = waveSurferRef.current.getCurrentTime() || 0;
        const newTime = Math.max(0, currentTime + seconds);
        const duration = waveSurferRef.current.getDuration() || 0;
        
        if (newTime > duration) {
          waveSurferRef.current.seekTo(1);
        } else {
          waveSurferRef.current.seekTo(newTime / duration);
        }
        
        // Sync with audio element
        if (audioElementRef.current) {
          audioElementRef.current.currentTime = newTime;
        }
        
        setCurrentTime(newTime);
        return;
      }
      
      if (audioElementRef.current) {
        const currentTime = audioElementRef.current.currentTime || 0;
        const newTime = Math.max(0, currentTime + seconds);
        const duration = audioElementRef.current.duration || 0;
        
        if (newTime > duration) {
          audioElementRef.current.currentTime = duration;
        } else {
          audioElementRef.current.currentTime = newTime;
        }
        
        setCurrentTime(newTime);
      }
    } catch (error) {
      console.error("AUDIO PLAYER: Error during skip:", error);
    }
  }, []);

  // CONSOLIDATED: Combined handleScrubberClick and handleSeek into one function
  const handleSeek = useCallback((e) => {
    if (!audioLoaded) return;
    
    try {
      const container = e.currentTarget;
      const rect = container.getBoundingClientRect();
      const relativeX = e.clientX - rect.left;
      const percentage = relativeX / rect.width;
      
      if (isNaN(percentage) || percentage < 0 || percentage > 1) return;
      
      const newTime = percentage * duration;
      
      // Use syncTimePosition from audio-sync-fix.js
      syncTimePosition({
        currentTime: newTime,
        audioElement: audioElementRef.current,
        waveSurfer: waveSurferRef.current
      });
      
      setCurrentTime(newTime);
      
      // Notify global audio state if available
      if (globalAudioRef.current && typeof globalAudioRef.current.publishEvent === 'function') {
        globalAudioRef.current.publishEvent('seek', { 
          percentage, 
          time: newTime,
          source: 'waveform-click' 
        });
      }
    } catch (error) {
      console.error("AUDIO PLAYER: Error in seek handler:", error);
    }
  }, [audioLoaded, duration, syncTimePosition]);

  // REMOVED: handleTimeUpdate and handlePositionChange functions are redundant

  // Handle volume changes
  const handleVolumeChange = useCallback((newVolume) => {
    setVolume(newVolume);
    
    try {
      // Update WaveSurfer volume
      if (waveSurferRef.current && !waveSurferRef.current._destroyed) {
        waveSurferRef.current.setVolume(newVolume);
      }
      
      // Update audio element volume
      if (audioElementRef.current) {
        audioElementRef.current.volume = newVolume;
      }
    } catch (error) {
      console.error("AUDIO PLAYER: Error changing volume:", error);
    }
  }, []);

  // Toggle mute state
  const toggleMute = useCallback(() => {
    const newMuteState = !isMuted;
    setIsMuted(newMuteState);
    
    try {
      // Update WaveSurfer mute state
      if (waveSurferRef.current && !waveSurferRef.current._destroyed) {
        if (typeof waveSurferRef.current.setMute === 'function') {
          waveSurferRef.current.setMute(newMuteState);
        } else {
          // Fallback if setMute is not available
          waveSurferRef.current.setVolume(newMuteState ? 0 : volume);
        }
      }
      
      // Update audio element mute state
      if (audioElementRef.current) {
        audioElementRef.current.muted = newMuteState;
      }
    } catch (error) {
      console.error("AUDIO PLAYER: Error toggling mute:", error);
    }
  }, [isMuted, volume]);

  const toggleFullscreen = useCallback(() => {
    console.log("Toggling fullscreen mode, current state:", isFullscreen);
  
    // Save detailed state to window globals
    window.__fsTransitionTime = currentTime || 0;
    window.__fsTransitionPlaying = isPlaying;
    window.__fsTransitionVolume = volume;
    window.__fsTransitionMuted = isMuted;
    window.__fsLastPosition = duration > 0 ? currentTime / duration : 0; // Store as percentage
  
    // Set a flag to indicate we're transitioning
    window.__wavesurferTransitionInProgress = true;
    
    // IMPORTANT: Store a reference to the audio element for reuse
    if (audioElementRef.current) {
      window.__transitionAudioElement = audioElementRef.current;
      console.log("Stored audio element in window.__transitionAudioElement for reuse");
      
      // Make sure it has the correct time
      if (typeof currentTime === 'number' && !isNaN(currentTime)) {
        try {
          window.__transitionAudioElement.currentTime = currentTime;
        } catch (e) {
          console.warn("Error setting time on transition element:", e);
        }
      }
      
      // Pause to prevent double playback during transition
      if (!window.__transitionAudioElement.paused) {
        try {
          window.__transitionAudioElement.pause();
        } catch (e) {
          console.warn("Error pausing transition element:", e);
        }
      }
    }
    
    // Use the transition preparation function
    if (typeof prepareFullscreenTransition === 'function') {
      const transitionResult = prepareFullscreenTransition({
        direction: isFullscreen ? 'exit' : 'enter',
        audioElement: audioElementRef.current,
        waveSurfer: waveSurferRef.current,
        currentUrl: audioUrl,
        isPlaying: isPlaying,
        currentTime: currentTime || 0,
        duration: duration || 0
      });
      console.log("Transition preparation result:", transitionResult);
    }
  
    // Toggle the fullscreen flag
    setIsFullscreen(prev => !prev);
  
    // Use a sequence of timeouts to ensure clean transition
    setTimeout(() => {
      try {
        // 1. First update WaveSurfer container if needed
        if (waveSurferRef.current && !waveSurferRef.current._destroyed) {
          const container = waveSurferRef.current.drawer.container;
          if (container) {
            const newWidth = container.clientWidth;
            console.log("Updating waveform container width:", newWidth);
            waveSurferRef.current.drawer.containerWidth = newWidth;
            waveSurferRef.current.drawBuffer();
            
            // Set position
            if (duration > 0) {
              waveSurferRef.current.seekTo(currentTime / duration);
            }
          }
        }
  
        // 2. Ensure audio element has correct time
        if (audioElementRef.current) {
          audioElementRef.current.currentTime = currentTime;
        }
  
        // 3. Handle playback state restoration after a delay
        setTimeout(() => {
          if (!mounted.current) return;
          
          if (isPlaying) {
            if (audioElementRef.current) {
              const playPromise = audioElementRef.current.play();
              if (playPromise) {
                playPromise.catch(err => {
                  console.warn("Error resuming after transition:", err);
                  
                  // Add one-time interaction listener for autoplay policy
                  const handleUserInteraction = () => {
                    if (audioElementRef.current) {
                      audioElementRef.current.play().catch(e => console.warn("Second play attempt failed:", e));
                    }
                    document.removeEventListener('click', handleUserInteraction);
                    document.removeEventListener('touchstart', handleUserInteraction);
                  };
                  document.addEventListener('click', handleUserInteraction, { once: true });
                  document.addEventListener('touchstart', handleUserInteraction, { once: true });
                });
              }
            }
          }
          
          // Clear transition flag after successful transition
          window.__wavesurferTransitionInProgress = false;
        }, 300);
      } catch (error) {
        console.warn("Error during fullscreen transition:", error);
        window.__wavesurferTransitionInProgress = false;
      }
    }, 200);
  }, [isFullscreen, audioUrl, isPlaying, currentTime, duration, volume, isMuted, prepareFullscreenTransition]);
  

  // Handle audio error events - simplified and kept
  const handleAudioError = useCallback((error) => {
    console.error("AUDIO PLAYER: Error event:", error);
    
    if (!mounted.current) return;
    
    // Show toast notification
    if (safeToastRef.current) {
      safeToastRef.current('error', 'Audio playback error. Trying alternative method.');
    }
    
    // Try to get fallback URL from global audio state
    if (audioUrl && globalAudioRef.current) {
      const fallbackUrl = globalAudioRef.current.getBestAudioUrl();
      if (fallbackUrl && fallbackUrl !== audioUrl) {
        setAudioUrl(fallbackUrl);
      }
    }
    
    // Call error callback if provided
    if (onError) {
      onError(error);
    }
  }, [audioUrl, onError]);

  // Handle export audio 
  const handleExportAudioHandler = useCallback(async () => {
    // Use provided export handler if available
    if (typeof onExportAudio === 'function') {
      try {
        await onExportAudio();
        return;
      } catch (err) {
        console.warn("AUDIO PLAYER: Error with provided export handler:", err);
      }
    }
    
    // Determine the best URL to download
    let downloadUrl = '';
    
    if (globalAudioRef.current && typeof globalAudioRef.current.getBestAudioUrl === 'function') {
      downloadUrl = globalAudioRef.current.getBestAudioUrl();
    }
    
    if (!downloadUrl && audioUrlRef.current) {
      downloadUrl = audioUrlRef.current;
    }
    
    if (!downloadUrl) {
      downloadUrl = audioUrl;
    }
    
    // Check for mixed URL
    const mixedUrl = sessionStorage.getItem('mixedAudioUrl') ||
      window.__mixedAudioUrl ||
      (globalAudioRef.current && globalAudioRef.current.state?.mixedAudioUrl);
      
    if (mixedUrl) {
      downloadUrl = mixedUrl;
    }
    
    // Validate URL
    if (!downloadUrl || !isValidUrl(downloadUrl)) {
      if (safeToastRef.current) {
        safeToastRef.current('error', 'No audio available to download');
      }
      return;
    }
    
    console.log("AUDIO PLAYER: Downloading from URL:", downloadUrl);
    
    if (safeToastRef.current) {
      safeToastRef.current('info', 'Preparing download...');
    }
    
    try {
      // Fetch the audio file
      const response = await fetch(downloadUrl, {
        method: 'GET',
        headers: { 'Accept': 'audio/mpeg, audio/*', 'Cache-Control': 'no-cache' },
        cache: 'no-store'
      });
      
      if (!response.ok) {
        throw new Error(`Failed to fetch audio: ${response.status} ${response.statusText}`);
      }
      
      // Create a blob from the response
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      
      // Create a download link and click it
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = 'meditation-' + Date.now() + '.mp3';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      
      // Clean up
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
      }, 200);
      
      if (safeToastRef.current) {
        safeToastRef.current('success', 'Downloading your meditation audio');
      }
    } catch (err) {
      console.warn("AUDIO PLAYER: Error using fetch method, trying direct link:", err);
      
      try {
        // Fallback to direct link download
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = 'meditation-' + Date.now() + '.mp3';
        a.target = '_blank';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        
        setTimeout(() => {
          document.body.removeChild(a);
        }, 200);
        
        if (safeToastRef.current) {
          safeToastRef.current('success', 'Downloading your meditation audio');
        }
      } catch (directErr) {
        console.error("AUDIO PLAYER: All download methods failed:", directErr);
        
        if (safeToastRef.current) {
          safeToastRef.current('error', 'Unable to download audio. Please try again.');
        }
      }
    }
  }, [audioUrl, onExportAudio, isValidUrl]);

  // Handle reset session
  const handleResetSessionHandler = useCallback(() => {
    // Use provided reset handler if available
    if (typeof onReset === 'function') {
      try {
        onReset();
        return;
      } catch (error) {
        console.error("AUDIO PLAYER: Error in provided reset handler:", error);
      }
    }
    
    try {
      // Clean up WaveSurfer
      if (waveSurferRef.current) {
        try {
          safeDestroyWaveSurfer(waveSurferRef.current);
          waveSurferRef.current = null;
        } catch (e) {
          console.warn("AUDIO PLAYER: Error destroying WaveSurfer:", e);
        }
      }
      
      // Clean up audio element
      if (audioElementRef.current) {
        try {
          audioElementRef.current.pause();
          audioElementRef.current.src = '';
          audioElementRef.current.load();
        } catch (e) {
          console.warn("AUDIO PLAYER: Error cleaning up audio element:", e);
        }

        audioElementRef.current = null;
      }
      
      // Clean up audio context
      if (audioContextRef.current) {
        try {
          audioContextRef.current.close();
        } catch (e) {
          console.warn("AUDIO PLAYER: Error closing AudioContext:", e);
        }
        audioContextRef.current = null;
      }
      
      // Clean up analyzer
      analyzerRef.current = null;
      setAnalyzerNode(null);
      
      // Reset global state if available
      if (globalAudioRef.current && typeof globalAudioRef.current.resetAllState === 'function') {
        globalAudioRef.current.resetAllState();
      } else if (typeof window !== 'undefined') {
        // Clear global flags
        window.__ttsKey = null;
        window.__ttsProgress = 0;
        window.__ttsProcessing = false;
        window.__mixedAudioUrl = null;
        window.__currentAudioUrl = null;
        window.__mixingInProgress = false;
        window.__mixingCompleted = false;
        window.__mixingFailed = false;
        
        try {
          // Clear session storage
          sessionStorage.removeItem('ttsKey');
          sessionStorage.removeItem('ttsProgress');
          sessionStorage.removeItem('ttsProcessing');
          sessionStorage.removeItem('mixedAudioUrl');
          sessionStorage.removeItem('currentAudioUrl');
          sessionStorage.removeItem('fallbackAudioUrl');
          sessionStorage.removeItem('mixingInProgress');
          sessionStorage.removeItem('mixingCompleted');
          sessionStorage.removeItem('mixingFailed');
        } catch (e) {
          console.warn("AUDIO PLAYER: Error clearing session storage:", e);
        }
      }
      
      // Clean up blob URLs
      blobUrlsRef.current.forEach(url => {
        try {
          URL.revokeObjectURL(url);
        } catch (e) {
          console.warn("AUDIO PLAYER: Error revoking blob URL:", e);
        }
      });
      blobUrlsRef.current = [];
      
      // Reset component state
      setAudioUrl('');
      setIsPlaying(false);
      setAudioLoaded(false);
      setIsLoadingAudio(true);
      setCurrentTime(0);
      setDuration(0);
      setIsInitialized(false);
      setInitComplete(false);
      setHasMixedAudio(false);
      setMixReady(false);
      setIsBackgroundTTSProcessing(false);
      setBackgroundTTSProgress(0);
      
      if (safeToastRef.current) {
        safeToastRef.current('success', 'Session reset. Ready for a new meditation.');
      }
    } catch (error) {
      console.error("AUDIO PLAYER: Error in reset handler:", error);
      
      if (safeToastRef.current) {
        safeToastRef.current('error', 'Failed to reset session');
      }
    }
  }, [onReset, safeDestroyWaveSurfer]);

  // Render visualizer based on current state
  const renderVisualizer = useCallback(() => {
    if (!useVisualizer) {
      // Simple pulsing circle when visualizer is disabled
      return (
        <div style={{ 
          width: '100%', 
          height: '100%', 
          backgroundColor: '#02073c', 
          borderRadius: '50%', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center' 
        }}>
          <div style={{ 
            width: '60%', 
            height: '60%', 
            borderRadius: '50%', 
            background: 'radial-gradient(circle, #7d12ff 0%, #5636f3 70%, #02073c 100%)', 
            opacity: isPlaying ? 0.8 : 0.5, 
            transition: 'all 0.3s ease', 
            animation: isPlaying ? 'pulse 3s infinite alternate' : 'none' 
          }}></div>
          <style jsx>{`
            @keyframes pulse {
              0% { transform: scale(0.95); opacity: 0.7; }
              100% { transform: scale(1.05); opacity: 0.9; }
            }
          `}</style>
        </div>
      );
    }
    
    // Use the VisualizerLoader component which will handle analyzer integration
    try {
      return (
        <VisualizerLoader
          analyzerNode={analyzerNode || analyzerRef.current}
          isPlaying={isPlaying}
          size={visualizerSize}
          audioUrl={audioUrl}
          fallbackOnly={!analyzerNode && !analyzerRef.current}
        />
      );
    } catch (error) {
      console.error("Error rendering visualizer:", error);
      
      // Fallback to simple pulsing circle if visualization fails
      return (
        <div style={{ 
          width: '100%', 
          height: '100%', 
          backgroundColor: '#02073c', 
          borderRadius: '50%', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center' 
        }}>
          <div style={{ 
            width: '60%', 
            height: '60%', 
            borderRadius: '50%', 
            background: 'radial-gradient(circle, #7d12ff 0%, #5636f3 70%, #02073c 100%)', 
            opacity: 0.6, 
            transition: 'all 0.3s ease' 
          }}></div>
        </div>
      );
    }
  }, [analyzerNode, isPlaying, useVisualizer, visualizerSize, audioUrl]);

  // Callback to update waveform data for sharing between standard and fullscreen modes
  const updateWaveformData = useCallback((data) => {
    if (Array.isArray(data) && data.length > 0) {
      waveformDataRef.current = data;
    }
  }, []);

  const renderFullScreenVisualizer = useCallback(() => {
    // Make sure we have a valid audio element before passing to the visualizer
    const audioEl = audioElementRef.current;
    
    // Ensure the audio context and analyzer are available
    const activeAnalyzer = analyzerRef.current || analyzerNode;
    
    console.log("Rendering fullscreen visualizer with:", { 
      audioElement: audioEl ? "Available" : "Not available",
      analyzer: activeAnalyzer ? "Available" : "Not available",
      currentTime: currentTime,
      duration: duration,
      isPlaying: isPlaying
    });
    
    return (
      <FullScreenVisualizer
        audioElement={audioEl}
        isPlaying={isPlaying}
        onPlayPause={togglePlayback}
        onToggleFullScreen={toggleFullscreen}
        colorPrimary="#7d12ff"
        colorSecondary="#5636f3"
        backgroundColor="#02073c"
        externalAnalyzer={activeAnalyzer}
        externalAudioContext={audioContextRef.current}
        currentTime={currentTime}
        duration={duration}
        onSkip={handleSkip}
        onSeek={(time) => {
          syncTimePosition({
            currentTime: time,
            audioElement: audioEl,
            waveSurfer: waveSurferRef.current
          });
        }}
        musicLibrary={musicLibrary}
        selectedMusic={selectedMusic}
        onMusicSelect={handleMusicSelect}
        waveformData={waveformDataRef.current}
        onVolumeChange={handleVolumeChange}
        onMuteToggle={toggleMute}
        volume={volume}
        isMuted={isMuted}
        useExistingAudioElement={true}
        audioSource={audioUrl} // Pass the current audio URL as a fallback
        waveformColor='rgba(255, 255, 255, 0.6)'
        progressColor='#7d12ff'
        suppressToasts={true}
      />
    );
  }, [
    isPlaying, 
    togglePlayback, 
    toggleFullscreen, 
    currentTime, 
    duration, 
    handleSkip, 
    syncTimePosition, 
    musicLibrary, 
    selectedMusic, 
    handleMusicSelect,
    handleVolumeChange,
    toggleMute,
    volume,
    isMuted,
    audioUrl, // Add this dependency
    analyzerNode // Add this dependency
  ]);

  // Waveform container ref callback
  const waveformContainerRefCallback = useCallback(node => {
    if (!node) return;
  
    // Depending on fullscreen flag, assign to one or the other ref
    if (isFullscreen) {
      mobileWaveformContainerRef.current = node;
    } else {
      waveformContainerRef.current = node;
    }
  
    // Ensure container dimensions are set properly
    ensureContainerDimensions(node, isMobile);
  
    // If an existing WaveSurfer instance is already attached, reuse it.
    if (node._wavesurfer && !node._wavesurfer._destroyed) {
      console.log("AUDIO PLAYER: Found existing WaveSurfer instance in container");
      waveSurferRef.current = node._wavesurfer;
      setWavesurferInitialized(true);
      return;
    }
  
    // Check container dimensions—if too small, wait and try again.
    const rect = node.getBoundingClientRect();
    if (rect.width <= 20 || rect.height <= 20) {
      console.warn("AUDIO PLAYER: Container has invalid dimensions, delaying initialization");
      setTimeout(() => {
        const newRect = node.getBoundingClientRect();
        console.log("New container dimensions:", newRect.width, "x", newRect.height);
        if (newRect.width > 20 && newRect.height > 20) {
          const wsInstance = initializeWaveSurfer();
          if (wsInstance && audioUrl) {
            loadAudioIntoWaveSurfer(audioUrl, wsInstance);
          }
        } else {
          console.warn("Container still has invalid dimensions, falling back to audio element");
          if (audioUrl && isValidUrl(audioUrl)) {
            const fallbackAudio = audioElementRef.current || createFallbackAudioElement(audioUrl);
            if (!audioElementRef.current) {
              audioElementRef.current = fallbackAudio;
            }
            setAudioLoaded(true);
            setIsLoadingAudio(false);
          }
        }
      }, 500);
      return;
    }
  
    // Otherwise, create a new WaveSurfer instance normally.
    setTimeout(() => {
      const wsInstance = initializeWaveSurfer();
      waveSurferRef.current = wsInstance;
      if (wsInstance && audioUrl) {
        loadAudioIntoWaveSurfer(audioUrl, wsInstance);
      }
    }, 50);
  }, [isFullscreen, isMobile, audioUrl]);
  

  // Call this in useEffect after setting fullscreen state
  useEffect(() => {
    // Small delay to allow UI to update first
    const updateTimer = setTimeout(() => {
      updateWaveSurferView();
    }, 300);
    
    return () => clearTimeout(updateTimer);
  }, [isFullscreen, updateWaveSurferView]);

  // Add effect to manage body class for toast control
  useEffect(() => {
    // Add class to body to control toast visibility in listen step
    document.body.classList.add('in-listen-step');
    
    return () => {
      document.body.classList.remove('in-listen-step');
    };
  }, []);

  // Add this useEffect to ensure fullscreen visualizer uses the same audio source
  useEffect(() => {
    // Skip if not fullscreen or no fullscreen visualizer component
    if (!isFullscreen || !document.querySelector('.fullscreen-visualizer')) return;
    
    const ensureSharedAudioAnalyzer = () => {
      // Get the global analyzer if available
      const globalAnalyzer = window.globalAudioState?._analyzer || null;
      
      if (globalAnalyzer && typeof globalAnalyzer.getByteFrequencyData === 'function') {
        console.log("AUDIO PLAYER: Using shared analyzer in fullscreen mode");
        
        // Find the visualizer component and inject the analyzer
        const visualizerComponent = document.querySelector('[data-visualizer-id]');
        if (visualizerComponent) {
          // Store reference to the analyzer on the DOM element for direct access
          visualizerComponent._sharedAnalyzer = globalAnalyzer;
          
          // Dispatch a custom event that the visualizer component can listen for
          visualizerComponent.dispatchEvent(new CustomEvent('analyzer-available', {
            detail: { analyzer: globalAnalyzer }
          }));
        }
      }
    };
    
    // Call immediately
    ensureSharedAudioAnalyzer();
    
    // Also set up a retry with delay in case the visualizer isn't ready yet
    const retryTimer = setTimeout(ensureSharedAudioAnalyzer, 500);
    
    return () => clearTimeout(retryTimer);
  }, [isFullscreen]);

  // Add this effect to generate waveform data when playing
  useEffect(() => {
    if (!isPlaying || !analyzerRef.current) return;
    
    const analyzerNode = analyzerRef.current;
    let animationFrame = null;
    
    const generateWaveformData = () => {
      try {
        if (!analyzerNode) return;
        
        const bufferLength = analyzerNode.frequencyBinCount || 1024;
        
        if (!analyzerNode.timeDataArray) {
          analyzerNode.timeDataArray = new Uint8Array(bufferLength);
        }
        
        analyzerNode.getByteTimeDomainData(analyzerNode.timeDataArray);
        
        // Create a simplified data representation
        const simplified = [];
        const step = Math.floor(bufferLength / 50);
        
        for (let i = 0; i < 50; i++) {
          const index = Math.min(i * step, bufferLength - 1);
          simplified.push((analyzerNode.timeDataArray[index] / 128) - 1);
        }
        
        updateWaveformData(simplified);
      } catch (err) {
        console.warn("Error generating waveform data:", err);
      }
      
      animationFrame = requestAnimationFrame(generateWaveformData);
    };
    
    generateWaveformData();
    
    return () => {
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
    };
  }, [isPlaying, updateWaveformData]);

  // Update audio URL when it changes
  useEffect(() => {
    // Skip if the URL hasn't changed
    if (!audioUrl || audioUrl === audioUrlRef.current) return;
    
    // Update reference
    audioUrlRef.current = audioUrl;
    
    console.log("AUDIO PLAYER: Audio URL changed:", audioUrl);
    
    // Update audio element if it exists
    if (audioElementRef.current && audioElementRef.current.src !== audioUrl) {
      try {
        audioElementRef.current.src = audioUrl;
        audioElementRef.current.load();
      } catch (err) {
        console.warn("AUDIO PLAYER: Error updating audio element URL:", err);
      }
    }
    
    // Update WaveSurfer if it exists
    if (waveSurferRef.current && !waveSurferRef.current._destroyed) {
      loadAudioIntoWaveSurfer(audioUrl, waveSurferRef.current);
    }
  }, [audioUrl, loadAudioIntoWaveSurfer]);

  // Initialize at component mount
// Initialize at component mount
useEffect(() => {
  console.log("AUDIO PLAYER: Component mounted, initializing...");
  mounted.current = true;
  isMountedRef.current = true;
  transitionCompleteRef.current = false;
  isDestroyingRef.current = false;
  initializationInProgress.current = false;
  
  // Store global audio state reference
  globalAudioStateRef.current = window.globalAudioState;
  globalAudioRef.current = window.globalAudioState;
  
  setComponentReady(true);
  
  // Initialize audio - RENAMED to avoid conflict with imported function
  const setupAudioPlayer = () => {
    console.log("AUDIO PLAYER: Initializing audio element and state...");
    
    try {
      // Check if we're in a transition
      const isInTransition = checkTransitionState();
      
      if (isInTransition && !transitionHandledRef.current) {
        console.log("AUDIO PLAYER: Handling transition state");
        
        // Handle transition specially for seamless audio continuation
        let bestUrl = null;
        
        // Try to find the best audio URL from various sources
        if (window.__mixedAudioUrl) {
          bestUrl = window.__mixedAudioUrl;
        } else if (window.__currentAudioUrl) {
          bestUrl = window.__currentAudioUrl;
        } else if (sessionStorage.getItem('mixedAudioUrl')) {
          bestUrl = sessionStorage.getItem('mixedAudioUrl');
        } else if (sessionStorage.getItem('currentAudioUrl')) {
          bestUrl = sessionStorage.getItem('currentAudioUrl');
        } else if (initialAudioUrl) {
          bestUrl = initialAudioUrl;
        } else if (window.__stableTransitionUrl) {
          bestUrl = window.__stableTransitionUrl;
        }
        
        // Use existing transition audio element if available
        if (window.__transitionAudioElement) {
          audioElementRef.current = window.__transitionAudioElement;
          console.log("AUDIO PLAYER: Using transition audio element");
        }
        
        // Set the URL
        if (bestUrl && isValidUrl(bestUrl)) {
          console.log("AUDIO PLAYER: Setting best URL from transition:", bestUrl);
          setAudioUrl(bestUrl);
          audioUrlRef.current = bestUrl;
          
          // If we have an audio element but no URL, set it
          if (audioElementRef.current && (!audioElementRef.current.src || audioElementRef.current.src !== bestUrl)) {
            audioElementRef.current.src = bestUrl;
            audioElementRef.current.load();
          }
        }
        
        // Mark transition as handled
        transitionHandledRef.current = true;
        
        // Delay WaveSurfer initialization to ensure stable transition
        const initTimeout = setTimeout(() => {
          if (!mounted.current) return;
          
          // Initialize analyzer for visualization regardless of WaveSurfer
          if (audioElementRef.current && !analyzerRef.current) {
            initializeAudioAnalysis();
          }
          
          // Mark audio as loaded to prevent infinite loading state
          setAudioLoaded(true);
          setIsLoadingAudio(false);
          setInitComplete(true);
          
          // Try to create WaveSurfer after transition completes
          const wsTimeout = setTimeout(() => {
            if (!mounted.current) return;
            
            try {
              // Only initialize WaveSurfer if container is ready
              const container = waveformContainerRef.current || mobileWaveformContainerRef.current;
              
              if (container && container.offsetWidth > 0 && container.offsetHeight > 0) {
                console.log("AUDIO PLAYER: Delayed WaveSurfer initialization");
                
                const wsInstance = initializeWaveSurfer();
                
                if (wsInstance && bestUrl) {
                  loadAudioIntoWaveSurfer(bestUrl, wsInstance);
                }
                
                waveSurferRef.current = wsInstance;
                setWavesurferInitialized(true);
              }
              
              transitionCompleteRef.current = true;
            } catch (err) {
              console.warn("AUDIO PLAYER: Error in delayed WaveSurfer init:", err);
            }
          }, 1000);
          
          pendingTimeoutsRef.current.push(wsTimeout);
        }, 100);
        
        pendingTimeoutsRef.current.push(initTimeout);
      } else {
        // Normal initialization path
        console.log("AUDIO PLAYER: Normal initialization path");
        
        // Use the imported function with a different name (initAudioFromUtils)
        // NOT calling the local function anymore which prevents recursion
        const result = initAudioFromUtils({
          audioUrl: initialAudioUrl || audioUrlRef.current,
          globalAudioState: globalAudioRef.current,
          audioElementRef,
          waveSurferRef,
          setAudioElement: (element) => {
            audioElementRef.current = element;
            if (globalAudioRef.current && !globalAudioRef.current._audioElement) {
              globalAudioRef.current._audioElement = element;
            }
          },
          createFallbackElement: createFallbackAudioElement,
          onAudioReady: handleAudioReady
        });
        
        if (result && result.success) {
          console.log("AUDIO PLAYER: Audio initialization successful");
          
          // Update URL state
          if (result.audioElement && result.audioElement.src) {
            setAudioUrl(result.audioElement.src);
            audioUrlRef.current = result.audioElement.src;
          } else if (initialAudioUrl) {
            setAudioUrl(initialAudioUrl);
            audioUrlRef.current = initialAudioUrl;
          }
          
          // Create WaveSurfer
          const wsTimeout = setTimeout(() => {
            if (!mounted.current) return;
            
            try {
              const wsInstance = initializeWaveSurfer();
              
              if (wsInstance && audioUrlRef.current) {
                loadAudioIntoWaveSurfer(audioUrlRef.current, wsInstance);
              }
              
              // Initialize analyzer after a short delay
              const analyzerTimeout = setTimeout(() => {
                if (!mounted.current) return;
                
                if (audioElementRef.current && !analyzerRef.current) {
                  initializeAudioAnalysis();
                }
                
                // Mark initialization as complete
                setInitComplete(true);
              }, 500);
              
              pendingTimeoutsRef.current.push(analyzerTimeout);
            } catch (err) {
              console.error("AUDIO PLAYER: Error initializing WaveSurfer:", err);
              setInitComplete(true);
            }
          }, 300);
          
          pendingTimeoutsRef.current.push(wsTimeout);
        } else {
          console.warn("AUDIO PLAYER: Audio initialization was not successful");
          
          // Try to create a fallback audio element
          if (initialAudioUrl && isValidUrl(initialAudioUrl)) {
            createFallbackAudioElement(initialAudioUrl);
          }
          
          setInitComplete(true);
        }
      }
      
      setIsInitialized(true);
    } catch (error) {
      console.error("AUDIO PLAYER: Error during initialization:", error);
      
      // Ensure we don't hang in loading state
      setIsInitialized(true);
      setInitComplete(true);
      
      // Try fallback approach
      try {
        if (initialAudioUrl && isValidUrl(initialAudioUrl)) {
          console.log("AUDIO PLAYER: Using fallback initialization");
          createFallbackAudioElement(initialAudioUrl);
          setAudioLoaded(true);
          setIsLoadingAudio(false);
        } else {
          console.log("AUDIO PLAYER: Creating silent audio fallback");
          const silentUrl = createFallbackAudioBlob();
          createFallbackAudioElement(silentUrl);
          setAudioLoaded(true);
          setIsLoadingAudio(false);
        }
      } catch (fallbackError) {
        console.error("AUDIO PLAYER: Fallback initialization failed:", fallbackError);
      }
    }
  };
  
  // Start initialization with the renamed function
  setupAudioPlayer();
  
  // Safety timeout to ensure audio eventually loads
  const safetyTimeout = setTimeout(() => {
    if (mounted.current && !audioLoaded) {
      console.warn("AUDIO PLAYER: Audio load timeout, using fallback");
      
      let fallbackUrl = null;
      
      // Try to get URL from various sources
      if (globalAudioRef.current && typeof globalAudioRef.current.getBestAudioUrl === 'function') {
        fallbackUrl = globalAudioRef.current.getBestAudioUrl();
      } else if (initialAudioUrl) {
        fallbackUrl = initialAudioUrl;
      } else {
        fallbackUrl = createFallbackAudioBlob();
      }
      
      createFallbackAudioElement(fallbackUrl);
      setAudioLoaded(true);
      setIsLoadingAudio(false);
    }
  }, 8000);
  
  pendingTimeoutsRef.current.push(safetyTimeout);
  
  // Return cleanup function
  return () => {
    console.log("AUDIO PLAYER: Component unmounting, cleaning up resources");
    
    mounted.current = false;
    isMountedRef.current = false;
    isDestroyingRef.current = true;
    
    // Clear all timeouts
    pendingTimeoutsRef.current.forEach(timeoutId => {
      if (timeoutId) clearTimeout(timeoutId);
    });
    pendingTimeoutsRef.current = [];
    
    // Abort all pending operations
    abortControllersRef.current.forEach(controller => {
      try {
        if (controller && typeof controller.abort === 'function') {
          controller.abort();
        }
      } catch (e) {
        // Ignore abort errors
      }
    });
    abortControllersRef.current = [];
    
    // Clean up WaveSurfer with a delay to ensure it completes
    if (waveSurferRef.current) {
      try {
        safeDestroyWaveSurfer(waveSurferRef.current);
      } catch (err) {
        console.warn("AUDIO PLAYER: Error destroying WaveSurfer:", err);
      }
      waveSurferRef.current = null;
    }
    
    // Don't destroy shared audio elements
    if (audioElementRef.current && !isUsingGlobalAudio) {
      try {
        audioElementRef.current.pause();
        audioElementRef.current.src = '';
        audioElementRef.current.load();
        audioElementRef.current = null;
      } catch (err) {
        console.warn("AUDIO PLAYER: Error cleaning up audio element:", err);
      }
    }
    
    // Clean up audio context
    if (audioContextRef.current) {
      try {
        audioContextRef.current.close();
      } catch (e) {
        console.warn("AUDIO PLAYER: Error closing AudioContext:", e);
      }
      audioContextRef.current = null;
    }
    
    // Clean up analyzer
    if (analyzerRef.current && analyzerRef.current._cleanup) {
      try {
        analyzerRef.current._cleanup();
      } catch (e) {
        // Ignore cleanup errors
      }
    }
    
    analyzerRef.current = null;
    setAnalyzerNode(null);
    
    // Clean up blob URLs
    blobUrlsRef.current.forEach(url => {
      try {
        URL.revokeObjectURL(url);
      } catch (e) {
        console.warn("AUDIO PLAYER: Error revoking blob URL:", e);
      }
    });
    
    blobUrlsRef.current = [];
    
    // Publish cleanup event
    if (globalAudioRef.current && typeof globalAudioRef.current.publishEvent === 'function') {
      try {
        globalAudioRef.current.publishEvent('audio-component-cleanup', {
          id: instanceId,
          timestamp: Date.now()
        });
      } catch (e) {
        // Ignore errors in event publishing
      }
    }
  };
}, [
  initialAudioUrl,
  isValidUrl,
  createFallbackAudioElement,
  initializeWaveSurfer,
  loadAudioIntoWaveSurfer,
  initializeAudioAnalysis,
  isUsingGlobalAudio,
  safeDestroyWaveSurfer,
  audioLoaded,
  createFallbackAudioBlob,
  checkTransitionState,
  handleAudioReady,
  initAudioFromUtils,
  audioUrl
]);

  return (
    <div
      className="listen-step-container"
      style={{
        margin: 0,
        padding: 0,
        width: '100%',
        height: isFullscreen ? '100vh' : 'auto',
        position: isFullscreen ? 'fixed' : 'relative',
        top: isFullscreen ? '0' : 'auto',
        left: isFullscreen ? '0' : 'auto',
        zIndex: isFullscreen ? 1000 : 'auto',
        backgroundColor: isFullscreen ? '#161616' : 'transparent',
        transition: 'height 0.3s ease',
        overflow: isFullscreen ? 'hidden' : 'visible',
      }}
    >
      {/* Global styles with toast notification control */}
      <style dangerouslySetInnerHTML={{ __html: getGlobalStyles(isMobile) }} />
  
      {isFullscreen ? (
        <div
          className="fullscreen-visualizer-wrapper"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            minHeight: '300px',  // <--- New: enforce a minimum height (adjust as needed)
            zIndex: 1000,
            backgroundColor: '#161616',
          }}
        >
          {audioElementRef.current ? (
            <FullScreenVisualizer
              audioElement={audioElementRef.current}
              isPlaying={isPlaying}
              onPlayPause={togglePlayback}
              onToggleFullScreen={toggleFullscreen}
              colorPrimary="#7d12ff"
              colorSecondary="#5636f3"
              backgroundColor="#02073c"
              externalAnalyzer={analyzerRef.current}
              externalAudioContext={audioContextRef.current}
              currentTime={currentTime}
              duration={duration}
              onSkip={handleSkip}
              // Make sure onSeek is properly defined and uses syncTimePosition
              onSeek={(time) => {
                console.log("FullScreen seek requested:", time);
                try {
                  // First try direct audio control for immediate feedback
                  if (audioElementRef.current) {
                    audioElementRef.current.currentTime = time;
                  }
                  // Then use syncTimePosition for comprehensive sync
                  syncTimePosition({
                    currentTime: time,
                    audioElement: audioElementRef.current,
                    waveSurfer: waveSurferRef.current,
                  });
                  setCurrentTime(time);
                } catch (error) {
                  console.error("Error during seek:", error);
                }
              }}
              musicLibrary={musicLibrary}
              selectedMusic={selectedMusic}
              onMusicSelect={handleMusicSelect}
              // Make sure waveformData is valid
              waveformData={waveformDataRef.current || []}
              // Make sure volume props are valid
              volume={typeof volume === 'number' ? volume : 1.0}
              isMuted={isMuted}
              onVolumeChange={(vol) => {
                handleVolumeChange(vol);
              }}
              onMuteToggle={toggleMute}
              waveformColor="rgba(255, 255, 255, 0.6)"
              progressColor="#7d12ff"
              suppressToasts={true}
              useExistingAudioElement={true} // This is important to prevent double audio
            />
          ) : (
            // Add a fallback if audioElement isn't ready yet
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              color: 'white'
            }}>
              <div>Preparing visualizer...</div>
            </div>
          )}
        </div>
      ) : (
        <div className="standard-player-container">
          {(!isFullscreen || !isMobile) && (
            <div className="step-header" style={{ textAlign: 'center', padding: '10px', color: '#fff' }}>
              <h2>Your Meditation is Ready</h2>
              <p>Listen, save, or create a new meditation</p>
            </div>
          )}
          
          {/* Main player UI */}
          <div className="music-player-mini" style={{
            width: '100%',
            maxWidth: '800px',
            margin: '24px auto',
            padding: '20px',
            backgroundColor: '#1C1C2E',
            backgroundImage: 'linear-gradient(135deg, #1E1C2E 0%, #0C0C14 100%)',
            borderRadius: '20px',
            boxShadow: '0 10px 30px rgba(0,0,0,0.3)',
            transition: 'all 0.3s ease'
          }}>
            {/* Track info & Visualizer */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '20px' }}>
              <div style={{ position: 'relative', width: '80px', height: '80px', borderRadius: '50%', flexShrink: 0, overflow: 'hidden', backgroundColor: '#02073c' }}>
                {renderVisualizer()}
                <button onClick={toggleVisualizer} style={{
                  position: 'absolute',
                  bottom: '4px',
                  right: '4px',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  color: 'white',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '10px',
                  zIndex: 10
                }}>
                  <IoImage size={12} />
                </button>
              </div>
              <div style={{ flex: 1, minWidth: 0, color: '#fff' }}>
              <h3 style={{ fontSize: '18px', fontWeight: '600', marginBottom: '8px' }}>{trackInfo.title}</h3>
                <p style={{ fontSize: '14px', color: 'rgba(255,255,255,0.7)', margin: 0 }}>{trackInfo.artist}</p>
              </div>
              {isMobile && (
                <button onClick={toggleFullscreen} style={{
                  background: 'linear-gradient(135deg, #7d12ff 0%, #5636f3 100%)',
                  border: 'none',
                  borderRadius: '50%',
                  width: '44px',
                  height: '44px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  boxShadow: '0 4px 10px rgba(125,18,255,0.3)',
                  color: '#fff'
                }}>
                  {isFullscreen ? <IoContract size={24} /> : <IoExpand size={24} />}
                </button>
              )}
            </div>
            
            {/* IMPROVED WAVEFORM CONTAINER */}
            <div style={{
              marginBottom: '10px',
              position: 'relative',
              backgroundColor: 'rgba(20,20,30,0.3)',
              borderRadius: '8px',
              padding: '8px',
              overflow: 'hidden',
              minHeight: '110px'
            }}>
              {/* Progress bar for visual continuity (always visible) */}
              <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: duration > 0 ? `${(currentTime / duration) * 100}%` : '0%',
                backgroundColor: 'rgba(125, 18, 255, 0.15)',
                borderRadius: '4px 0 0 4px',
                zIndex: 1,
                pointerEvents: 'none'
              }} />
              
              {/* Waveform container - modified for proper scrolling */}
              <div 
                ref={waveformContainerRefCallback} 
                style={{
                  height: isMobile ? '60px' : '80px',
                  width: '100%',
                  position: 'relative',
                  marginBottom: '6px',
                  overflow: 'hidden',
                  borderRadius: '4px',
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  cursor: 'pointer'
                }} 
                onClick={handleSeek} 
              />
              
              {/* Simplified timeline container */}
              <div 
                ref={timelineContainerRef} 
                style={{ 
                  height: '20px', 
                  width: '100%', 
                  overflow: 'hidden',
                  opacity: 0.7,
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  borderRadius: '4px'
                }} 
              />
              
              {/* Time display (current / total) */}
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: 'white',
                fontSize: '12px',
                marginTop: '6px',
                padding: '0 2px'
              }}>
                <span>{formatTime(currentTime)}</span>
                <span>{formatTime(duration)}</span>
              </div>
              
              {/* Loading overlay - only show during initial load */}
              {isLoadingAudio && (
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: '4px',
                  backgroundColor: 'rgba(0,0,0,0.4)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 20
                }}>
                  <div style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    border: '3px solid rgba(125,18,255,0.3)',
                    borderTopColor: '#7d12ff',
                    animation: 'spin 1s linear infinite'
                  }} />
                </div>
              )}
            </div>
            
            {/* Player controls */}
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: '20px' }}>
              <button onClick={() => handleSkip(-10)} disabled={!audioLoaded} style={{
                backgroundColor: 'rgba(255,255,255,0.05)',
                border: 'none',
                borderRadius: '50%',
                width: '44px',
                height: '44px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                color: '#fff',
                opacity: audioLoaded ? 1 : 0.5
              }}>
                <IoPlaySkipBack size={24} />
              </button>
              <button onClick={togglePlayback} disabled={!audioLoaded} style={{
                background: 'linear-gradient(135deg, #7d12ff 0%, #5636f3 100%)',
                border: 'none',
                borderRadius: '50%',
                width: '60px',
                height: '60px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                boxShadow: '0 6px 15px rgba(125,18,255,0.3)',
                color: '#fff',
                opacity: audioLoaded ? 1 : 0.5
              }}>
                {isPlaying ? <IoPauseCircle size={32} /> : <IoPlayCircle size={32} />}
              </button>
              <button onClick={() => handleSkip(10)} disabled={!audioLoaded} style={{
                backgroundColor: 'rgba(255,255,255,0.05)',
                border: 'none',
                borderRadius: '50%',
                width: '44px',
                height: '44px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                color: '#fff',
                opacity: audioLoaded ? 1 : 0.5
              }}>
                <IoPlaySkipForward size={24} />
              </button>
              <button onClick={toggleMute} disabled={!audioLoaded} style={{
                backgroundColor: 'rgba(255,255,255,0.05)',
                border: 'none',
                borderRadius: '50%',
                width: '44px',
                height: '44px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                color: '#fff',
                opacity: audioLoaded ? 1 : 0.5
              }}>
                {isMuted ? <IoVolumeOff size={24} /> : <IoVolumeHigh size={24} />}
              </button>
            </div>
            
            {/* Volume slider */}
            {!isMobile && (
              <div style={{ display: 'flex', alignItems: 'center', gap: '12px', marginBottom: '20px', padding: '0 16px' }}>
                <IoVolumeOff size={16} style={{ color: 'rgba(255,255,255,0.6)' }} />
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={volume}
                  onChange={(e) => handleVolumeChange(parseFloat(e.target.value))}
                  style={{
                    flex: 1,
                    height: '4px',
                    appearance: 'none',
                    backgroundColor: 'rgba(255,255,255,0.1)',
                    outline: 'none',
                    borderRadius: '2px',
                    background: `linear-gradient(to right, #7d12ff 0%, #7d12ff ${volume * 100}%, rgba(255,255,255,0.1) ${volume * 100}%, rgba(255,255,255,0.1) 100%)`
                  }}
                />
                <IoVolumeHigh size={16} style={{ color: 'rgba(255,255,255,0.6)' }} />
              </div>
            )}
            
            {/* Bottom row buttons */}
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '12px' }}>
              <button onClick={togglePlaylist} style={{
                flex: 1,
                padding: '12px',
                backgroundColor: 'rgba(255,255,255,0.05)',
                border: '1px solid rgba(255,255,255,0.1)',
                borderRadius: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                color: 'rgba(255,255,255,0.8)',
                fontSize: '14px',
                fontWeight: '500',
                cursor: 'pointer'
              }}>
                <IoList size={18} />
                <span>Playlist</span>
              </button>
              <button onClick={handleExportAudioHandler} style={{
                flex: 1,
                padding: '12px',
                backgroundColor: 'rgba(255,255,255,0.05)',
                border: '1px solid rgba(255,255,255,0.1)',
                borderRadius: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                color: 'rgba(255,255,255,0.8)',
                fontSize: '14px',
                fontWeight: '500',
                cursor: 'pointer'
              }}>
                <IoDownload size={18} />
                <span>Download</span>
              </button>
              {!isMobile && (
                <button onClick={toggleFullscreen} style={{
                  flex: 1,
                  padding: '12px',
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  border: '1px solid rgba(255,255,255,0.1)',
                  borderRadius: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  color: 'rgba(255,255,255,0.8)',
                  fontSize: '14px',
                  fontWeight: '500',
                  cursor: 'pointer'
                }}>
                  <IoExpand size={18} />
                  <span>Fullscreen</span>
                </button>
              )}
              {typeof onReset === 'function' && (
                <button onClick={handleResetSessionHandler} style={{
                  flex: 1,
                  padding: '12px',
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  border: '1px solid rgba(255,255,255,0.1)',
                  borderRadius: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  color: 'rgba(255,255,255,0.8)',
                  fontSize: '14px',
                  fontWeight: '500',
                  cursor: 'pointer'
                }}>
                  <IoRefresh size={18} />
                  <span>New</span>
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListenStep;