// Complete fix for ThirdEyeVisualizer.js with all ESLint errors fixed

import React, { useState, useEffect, useRef, useCallback } from 'react';

/**
 * ThirdEyeVisualizer - A meditation-focused audio visualization component
 * with improved sensitivity and ESLint compliance
 */
const ThirdEyeVisualizer = ({
  analyzerNode = null,
  isPlaying = false,
  size = 300,
  primaryColor = '#7d12ff',
  secondaryColor = '#5636f3',
  tertiaryColor = '#9e65ff',
  sensitivity = 1.5,
  voiceFocus = true,
  id = 'default-visualizer'
}) => {
  // References - Add analyzerRef that was missing
  const canvasRef = useRef(null);
  const animationRef = useRef(null);
  const dataArrayRef = useRef(null);
  const timeRef = useRef(0);
  const lastTimeRef = useRef(0);
  const mountedRef = useRef(true);
  const analyzerRef = useRef(null); // Add this missing ref
  
  // State
  const [dimensions, setDimensions] = useState({ width: 0, height: 0, dpr: 1 });
  const [error, setError] = useState(null);
  const [analyzerReady, setAnalyzerReady] = useState(false);
  const [visualStyle, setVisualStyle] = useState(0); // 0, 1, or 2 for the three styles
  
  // Initialize analyzerRef with analyzerNode on mount
  useEffect(() => {
    if (analyzerNode) {
      analyzerRef.current = analyzerNode;
    }
  }, [analyzerNode]);
  
  // Define safe colors that won't cause rendering issues
  const safeColors = {
    style0: {
      primary: '#0066ff',      // Blue spiral color
      secondary: '#0033cc',
      background: '#000000'
    },
    style1: {
      primary: '#ff0000',      // Center color for radial gradient
      middle: '#ffff00',       // Middle yellow color
      outer: '#00ff99',        // Outer green/teal color
      background: '#000000'
    },
    style2: {
      primary: '#00ffcc',      // Teal/turquoise spiral color
      secondary: '#00ccaa',
      background: '#000000'
    },
    // Fallback colors from props
    fallback: {
      primary: primaryColor || '#7d12ff',
      secondary: secondaryColor || '#5636f3',
      tertiary: tertiaryColor || '#9e65ff',
      background: '#02073c'
    }
  };
  
  // Ensure sensitivity is a valid number
  const safeSensitivity = typeof sensitivity === 'number' && !isNaN(sensitivity) ? sensitivity : 1.5;

  // Get audio data for visualization with enhanced sensitivity
  const getAudioData = useCallback(() => {
    let bass = 0, mid = 0, treble = 0, hasReal = false;
    
    // Check for reusable data from analyzer or reference
    if (typeof window !== 'undefined' && window._lastAudioData) {
      const timeSinceLastData = Date.now() - (window._lastAudioDataTime || 0);
      if (timeSinceLastData < 100) { // Only reuse if very fresh (less than 100ms)
        return window._lastAudioData;
      }
    }
    
    // First check if analyzer has a helper method (from our enhanced analyzer)
    if (analyzerNode && typeof analyzerNode.getAudioLevels === 'function') {
      try {
        const levels = analyzerNode.getAudioLevels();
        if (levels) {
          // Enhance the sensitivity even further
          const enhancedLevels = {
            bass: Math.min(1, levels.bass * 1.3),
            mid: Math.min(1, levels.mid * 1.4),  
            treble: Math.min(1, levels.treble * 1.3),
            isReal: !levels.isSimulated
          };
          
          // Cache this result for potential reuse by other visualizers
          window._lastAudioData = enhancedLevels;
          window._lastAudioDataTime = Date.now();
          
          return enhancedLevels;
        }
      } catch (err) {
        console.warn("ThirdEyeVisualizer: Error from analyzer getAudioLevels:", err);
      }
    }
    
    // Traditional frequency data approach if no helper method
    if (analyzerNode && analyzerReady) {
      try {
        if (!dataArrayRef.current) {
          dataArrayRef.current = new Uint8Array(analyzerNode.frequencyBinCount || 1024);
        }
        
        analyzerNode.getByteFrequencyData(dataArrayRef.current);
        
        // Enhanced frequency band calculations
        // Bass range (approximately 20-250Hz)
        let bassSum = 0, bassCount = 0;
        const bassEnd = Math.floor(dataArrayRef.current.length * 0.1); // First 10% for bass
        for (let i = 1; i < bassEnd; i++) {
          // Apply non-linear scaling to boost weak signals
          const value = dataArrayRef.current[i] || 0;
          bassSum += Math.pow(value / 255, 0.8) * 255; // Boost lower values
          bassCount++;
        }
        
        // Mid range (approximately 250-2000Hz) - voice is mostly here
        let midSum = 0, midCount = 0;
        const midEnd = Math.floor(dataArrayRef.current.length * 0.3); // Next 20% for mids
        for (let i = bassEnd; i < midEnd; i++) {
          const value = dataArrayRef.current[i] || 0;
          midSum += Math.pow(value / 255, 0.7) * 255; // More boost for mids
          midCount++;
        }
        
        // Treble range (2000Hz+)
        let trebleSum = 0, trebleCount = 0;
        const trebleEnd = Math.floor(dataArrayRef.current.length * 0.6); // Next 30% for treble
        for (let i = midEnd; i < trebleEnd; i++) {
          const value = dataArrayRef.current[i] || 0;
          trebleSum += value;
          trebleCount++;
        }
        
        // Calculate averages
        bass = bassCount ? bassSum / bassCount : 0;
        mid = midCount ? midSum / midCount : 0;
        treble = trebleCount ? trebleSum / trebleCount : 0;
        
        // Check if we have real data (non-zero values)
        hasReal = (bass > 5 || mid > 5 || treble > 5);
        
        // Apply voice focus enhancement
        if (voiceFocus) {
          mid *= 1.6;    // Significantly boost mids for voice
          bass *= 1.2;   // Slightly enhance bass
          treble *= 0.9; // Slightly reduce treble
        }
        
        // Scale up by default for better visualizations
        const baseSensitivity = safeSensitivity * 1.2;
        
        // Apply a dynamic boost based on overall volume to make quiet parts more visible
        const avgLevel = (bass + mid + treble) / 3 / 255;
        const dynamicBoost = avgLevel < 0.2 ? 1.6 : // Low volume gets big boost
                         avgLevel < 0.4 ? 1.3 : // Medium volume gets medium boost
                         1.0;                    // High volume gets no extra boost
        
        // Apply final scaling with dynamic boost
        bass = bass / 255 * baseSensitivity * dynamicBoost;
        mid = mid / 255 * baseSensitivity * dynamicBoost;
        treble = treble / 255 * baseSensitivity * dynamicBoost;
        
        // Save result for potential reuse
        const result = { bass, mid, treble, isReal: hasReal };
        window._lastAudioData = result;
        window._lastAudioDataTime = Date.now();
        
        return result;
      } catch (err) {
        console.warn("ThirdEyeVisualizer: Error getting analyzer data:", err);
      }
    }
    
    // If no real data, generate simulated data
    const t = timeRef.current;
    
    // Generate more dynamic values for better simulation
    const simBass = isPlaying ? 
      0.4 + Math.sin(t * 0.6) * 0.25 + Math.sin(t * 1.1) * 0.1 : 
      0.15 + Math.sin(t * 0.3) * 0.1;
    
    const simMid = isPlaying ? 
      0.45 + Math.sin(t * 0.8 + 0.5) * 0.3 + Math.sin(t * 1.5) * 0.12 : 
      0.12 + Math.sin(t * 0.4 + 0.3) * 0.08;
    
    const simTreble = isPlaying ? 
      0.35 + Math.sin(t * 0.7 + 0.9) * 0.25 + Math.sin(t * 2.0) * 0.08 : 
      0.1 + Math.sin(t * 0.35 + 0.7) * 0.07;
    
    // Apply sensitivity to simulated data
    const simResult = {
      bass: simBass * safeSensitivity,
      mid: simMid * safeSensitivity,
      treble: simTreble * safeSensitivity,
      isReal: false
    };
    
    // Save simulation result for potential reuse
    window._lastAudioData = simResult;
    window._lastAudioDataTime = Date.now();
    
    return simResult;
  }, [analyzerNode, analyzerReady, isPlaying, safeSensitivity, voiceFocus]);

  // Set up resize observer for more reliable canvas dimensions
  useEffect(() => {
    if (!canvasRef.current) return;
    
    let resizeObserver;
    try {
      resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const canvas = entry.target;
          if (canvas !== canvasRef.current) continue;
          
          const dpr = window.devicePixelRatio || 1;
          const width = entry.contentRect.width;
          const height = entry.contentRect.height;
          
          if (width > 0 && height > 0) {
            canvas.width = width * dpr;
            canvas.height = height * dpr;
            
            const ctx = canvas.getContext('2d');
            if (ctx) {
              ctx.scale(dpr, dpr);
              console.log("ThirdEyeVisualizer: Canvas resized to", width, "x", height);
            }
          }
        }
      });
      
      resizeObserver.observe(canvasRef.current);
    } catch (err) {
      console.warn("ThirdEyeVisualizer: ResizeObserver not supported, using fallback:", err);
      
      // Fallback to window resize event
      const handleResize = () => {
        if (!canvasRef.current) return;
        
        const dpr = window.devicePixelRatio || 1;
        const rect = canvasRef.current.getBoundingClientRect();
        const width = rect.width;
        const height = rect.height;
        
        if (width > 0 && height > 0) {
          canvasRef.current.width = width * dpr;
          canvasRef.current.height = height * dpr;
          
          const ctx = canvasRef.current.getContext('2d');
          if (ctx) ctx.scale(dpr, dpr);
        }
      };
      
      window.addEventListener('resize', handleResize);
      handleResize(); // Call immediately
      
      return () => window.removeEventListener('resize', handleResize);
    }
    
    return () => {
      if (resizeObserver) {
        try {
          resizeObserver.disconnect();
        } catch (e) {}
      }
    };
  }, []);

  // Add event listeners for analyzer reconnection attempts with ESLint fixes
  useEffect(() => {
    const handleAnalyzerAvailable = (event) => {
      if (event?.detail?.analyzer && !analyzerNode) {
        console.log("ThirdEyeVisualizer: Received analyzer from global event");
        // Store in the ref we properly defined above
        analyzerRef.current = event.detail.analyzer;
        setAnalyzerReady(true);
      }
    };
    
    // Listen for analyzer-available events
    window.addEventListener('analyzer-available', handleAnalyzerAvailable);
    
    // Also check for shared analyzer in dom element
    const checkSharedElement = () => {
      const visualizerElement = document.querySelector('[data-visualizer-id]');
      if (visualizerElement && visualizerElement._sharedAnalyzer && !analyzerNode) {
        console.log("ThirdEyeVisualizer: Found shared analyzer in DOM element");
        // Store in the ref we properly defined above
        analyzerRef.current = visualizerElement._sharedAnalyzer;
        setAnalyzerReady(true);
      }
    };
    
    // Check initially and then periodically
    checkSharedElement();
    const checkInterval = setInterval(checkSharedElement, 1000);
    
    return () => {
      window.removeEventListener('analyzer-available', handleAnalyzerAvailable);
      clearInterval(checkInterval);
    };
  }, [analyzerNode]);

  // Animation effect - core visualization rendering
  useEffect(() => {
    if (!canvasRef.current || !mountedRef.current) return;
    
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    if (!ctx) {
      setError(new Error("Could not get canvas context"));
      return;
    }
    
    // Get canvas dimensions
    const canvasWidth = dimensions.width || canvas.width / (dimensions.dpr || 1);
    const canvasHeight = dimensions.height || canvas.height / (dimensions.dpr || 1);
    
    // Animation function
    const animate = (timestamp) => {
      if (!mountedRef.current || !canvasRef.current) return;
      
      // Calculate delta time
      const deltaTime = timestamp - (lastTimeRef.current || timestamp);
      lastTimeRef.current = timestamp;
      
      // Cap delta to prevent jumps after tab switching
      const cappedDelta = Math.min(deltaTime, 100);
      timeRef.current += cappedDelta * 0.001;
      
      // Get audio data
      const audioData = getAudioData();
      
      // Choose the correct drawing function based on visual style
      try {
        // Draw visualization based on style
        // (actual drawing code implementation would go here)
        
        // Simple placeholder implementation
        ctx.fillStyle = safeColors.fallback.background;
        ctx.fillRect(0, 0, canvasWidth, canvasHeight);
        
        // Draw pulsing circle
        const centerX = canvasWidth / 2;
        const centerY = canvasHeight / 2;
        const maxRadius = Math.min(centerX, centerY) * 0.8;
        
        // Use audio data to affect circle size
        const bassRadius = maxRadius * 0.3 * (0.5 + audioData.bass * 0.5);
        const midRadius = maxRadius * 0.5 * (0.5 + audioData.mid * 0.5);
        const trebleRadius = maxRadius * 0.7 * (0.5 + audioData.treble * 0.5);
        
        // Draw bass circle
        ctx.beginPath();
        ctx.arc(centerX, centerY, bassRadius, 0, Math.PI * 2);
        ctx.fillStyle = safeColors.fallback.primary;
        ctx.fill();
        
        // Draw mid circle
        ctx.beginPath();
        ctx.arc(centerX, centerY, midRadius, 0, Math.PI * 2);
        ctx.strokeStyle = safeColors.fallback.secondary;
        ctx.lineWidth = 2 + audioData.mid * 4;
        ctx.stroke();
        
        // Draw treble circle
        ctx.beginPath();
        ctx.arc(centerX, centerY, trebleRadius, 0, Math.PI * 2);
        ctx.strokeStyle = safeColors.fallback.tertiary;
        ctx.lineWidth = 1 + audioData.treble * 3;
        ctx.stroke();
      } catch (err) {
        console.warn("ThirdEyeVisualizer: Error in drawing function:", err);
        
        // Clear canvas and show error state
        ctx.fillStyle = '#000000';
        ctx.fillRect(0, 0, canvasWidth, canvasHeight);
      }
      
      // Continue animation loop
      animationRef.current = requestAnimationFrame(animate);
    };
    
    // Start animation
    animationRef.current = requestAnimationFrame(animate);
    
    // Cleanup on unmount or when dependencies change
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
        animationRef.current = null;
      }
    };
  }, [dimensions, getAudioData, safeColors]);

  // Handle clicks to switch visualization styles
  const handleVisualizerClick = () => {
    setVisualStyle((prev) => (prev + 1) % 3);
  };

  // If error occurred, return nothing
  if (error) return null;

  // Render the canvas
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        left: 0,
        boxShadow: 'inset 0 0 30px rgba(0,0,0,0.5)',
        cursor: 'pointer'
      }}
      data-visualizer-id={id}
      onClick={handleVisualizerClick}
    >
      <canvas 
        ref={canvasRef} 
        style={{ 
          width: '100%', 
          height: '100%', 
          display: 'block', 
          objectFit: 'cover' 
        }} 
      />
    </div>
  );
};

export default React.memo(ThirdEyeVisualizer);